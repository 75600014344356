<template>
  <div v-if="trans" class="container">
    <div class="container-wrapper">
      <div class="main-item">
        <div class="wrapper">
          <div style="margin-top: 100px;">
            <user-info />
          </div>


          <!-- Title -->
          <div class="title">
            <h1> {{ trans.confirmPayment }} </h1>
            <span class="description"> {{ trans.pleaseConfirmTransactionDetailsBelow }}</span>
          </div>
          <!-- Table: Payment details -->
          <div class="tzp-table">
            <div class="tzp-row" v-if="fid">
              <div class="tzp-col"> {{ trans.flowId }}
                <!-- <tz-icon name="info-o" size="16" /> : -->
              </div>
              <div class="tzp-col"> {{ fid }} </div>
            </div>
            <div class="tzp-row">
              <div v-if="transaction.beneficiaryAccountNumber" class="tzp-col"> {{ trans.beneficiary }}:</div>
              <div v-else class="tzp-col"> {{ trans.merchant }}:</div>
              <div class="tzp-col" style="display: flex; justify-content: end;"> <img v-if="merchantImage"
                  :src="merchantImage" height="36" style="margin-right: 8px;">
                {{ merchant }}
              </div>
            </div>
            <div class="tzp-row" v-if="paymentMethod">
              <div class="tzp-col"> {{ trans.account }}:</div>
              <div class="tzp-col" style="display: flex; justify-content: end;"> <img v-if="paymentMethodAvatar"
                  :src="paymentMethodAvatar" height="36" style="margin-right: 8px;">
                {{ paymentMethod }}
              </div>
            </div>
            <div class="tzp-row">
              <div class="tzp-col"> {{ trans.amount }}:</div>
              <div class="tzp-col">
                <template v-if="usesForeignAmount">
                  <b> {{ foreignAmountStr }} <small> {{ transaction.payerOrgUser.currencyCode }} </small> </b> <span
                    style="transform: scale(.86);"> ({{ amountStr }} <small>{{ transaction.currencyCode }}</small>) </span>
                  <!-- <b> {{ foreignAmountStr }} </b> <span style="transform: scale(.86);"> ({{ amountStr }}) </span> -->
                </template>
                <template v-else>
                  {{ amountStr }}
                </template>
              </div>
            </div>
            <div class="tzp-row" v-if="description">
              <div class="tzp-col"> {{ trans.description }}:</div>
              <div class="tzp-col cursor-pointer" @click="showFullDescription">
                <div class="tz-ellipsis" style="text-align: right;">
                  {{ description }}
                </div>
              </div>
            </div>
            <div class="tzp-row" v-if="hasFees">
              <div class="tzp-col"> {{ trans.fees }}:</div>
              <div class="tzp-col"> {{ feesStr }}</div>
            </div>
            <div v-if="hasBonus" class="tzp-row">
              <div class="tzp-col"> {{ trans.bonuses }}:</div>
              <div class="tzp-col">{{ transaction.payerOrgUser.currencyCode }} {{ transaction.payerOrgUser.bonusAmount }}</div>
            </div>
            <div class="tzp-row total" v-if="netAmount && netAmount != amountStr">
              <div class="tzp-col"> {{ trans.netToPay }}:</div>
              <div class="tzp-col">
                {{ netAmount || amountToPayStr }}
              </div>
            </div>
          </div>
          <!-- Action buttons -->
          <div class="action-buttons">
            <tz-button type="primary" color="#922690" @click="continueClicked" :text="trans.continue"></tz-button>
            <p v-if="!hideCancel" class="cancel" @click="cancelClicked"> {{ trans.changePaymentMethod }}</p>
          </div>
          <sign-in />
          <copyright style="font-size: 12px;" />
        </div>
      </div>
      </div>
  </div>

</template>

<script>

import websdkStore from '../../websdk.store';
import { Notify, Popup, Form, Field, CellGroup, Icon } from 'tzui-vue';
import Copyright from './Copyright.vue';
import { formatCurrency } from '../../utils';
import { reactive } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { clearLocal, getLocal, isMobile, setLocal, truncateTextTo } from '../../../../../utils';
import { sendSMSOTP, verifySMSOTP, whoami } from '../../api';
import UserInfo from "./UserInfo.vue";
import Login from "./Login.vue";
/* When the user clicks on the button, 
toggle between hiding and showing the dropdown content */

// Close the dropdown if the user clicks outside of it
// window.onclick = function(e) {
//   //console.log("Click");
//   if (!e.target.matches('.dropbtn')) {
//   var myDropdown = document.getElementById("myDropdown");
//     if (myDropdown.classList.contains('show')) {
//       myDropdown.classList.remove('show');
//     }
//   }
// }

export default {
  components: {
    SignIn: Login,
    UserInfo,
    Copyright,
    Popup,
    Form,
    Field,
    CellGroup,
    Notify,
    VueTelInput,
    Icon,
  },
  data() {
    return {
      lang: getLocal('lang') || 'en',
      tzpayToken: null,
      currentUser: {},
      phoneNumber: null,
      phoneIsValid: false,
      OTPCode: null,
      showOTP: false,
      showPopUp: false,
      trans: websdkStore.trans.en,
      transaction: websdkStore.paymentInfo,
      bindProps: {
        mode: "international",
        validCharactersOnly: true,
        inputOptions: {
          placeholder: ["Mobile"],
          required: true,
          styleClasses: ['form-control']
        },
      },
    }
  },
  props: {
  },
  mounted() {
    // //console.log("Trans",this.trans);
    //console.log("Lang", websdkStore.lang);
    this.trans = websdkStore.trans[websdkStore.lang];
    // //console.log("Trans.Lang",this.trans);

    const exitingToken = getLocal("tzpay-token");

    if (exitingToken != null) {
      this.tzpayToken = exitingToken;
    } else {
      this.tzpayToken = null;
    }

    whoami()
      .then(res => {
        //console.log("res------ whoami response------", res)
        if (res.success) {
          this.currentUser = res.data.userInfo;
        }
      })
      .catch(err => {
        //console.log('=========error=======', err);
        clearInterval(this.intervalId);
        Notify({
          duration: 5000,
          message: 'An error occurred while get the user infos. Try refresh',
          type: 'danger'
        });
      })


  },
  setup() {
    const state = reactive({
      username: '',
      password: '',
    });
    const onSubmit = (values) => {
      //console.log('submit', values);
    };

    return {
      state,
      onSubmit,
    };
  },
  computed: {
    flow(){
      return websdkStore.paymentFlow || {};
    },
    fid() {
      return this.transaction?.flowId || null;
    },
    description() {
      return truncateTextTo(this.transaction.description, 45);
    },
    hideCancel() {
      return this.$route.meta?.hideCancel || false;
    },
    amountStr() {
      return formatCurrency(this.transaction?.amount, this.transaction?.currencyCode);
    },
    hasFees() {
      return this.transaction.payerOrgUser && this.transaction.payerOrgUser.fee;
    },
    hasBonus() {
      return this.transaction.payerOrgUser && this.transaction.payerOrgUser.bonusAmount;
    },
    feesStr() {
      return formatCurrency(this.transaction.payerOrgUser?.fee, this.transaction.payerOrgUser?.currencyCode ?? this.transaction?.currencyCode);
    },
    amountToPayStr() {
      return formatCurrency(this.transaction.amount + Number(this.transaction.fees || 0), this.transaction?.currencyCode);
    },
    merchant() {
      let name = null
      if(this.flow.customization && this.flow.customization.title){
        name = this.flow.customization.title
      }else if(this.flow.app && this.flow.app.name){
        name = this.flow.app.name;
      }
      name = truncateTextTo(name || this.transaction.beneDisplayName || this.transaction.beneName || this.transaction.beneRealName || this.transaction.beneUser?.displayName, 40);
      return name;
    },
    merchantImage() {
      if(isMobile()) return null;
      if(this.flow.customization && this.flow.customization.logoUrl){
        return this.flow.customization.logoUrl
      }
      if(this.flow.app && this.flow.app.avatarUrl){
        return this.flow.app.avatarUrl;
      }
      if(this.transaction.beneUser && this.transaction.beneUser?.avatarUrl){
        return this.transaction.beneUser.avatarUrl;
      }
      return null;
    },
    bonusStr() {
      return null; S
    },
    paymentMethod() {
      return this.transaction.payerAccount?.name;
    },
    paymentMethodAvatar() {
      return isMobile() ? null : this.transaction.payerAccount?.avatarUrl;
    },
    netAmount() {
      return formatCurrency(this.transaction.payerOrgUser?.netAmount || this.transaction.amount, this.transaction.payerOrgUser?.currencyCode || this.transaction?.currencyCode);
    },
    usesForeignAmount() {
      return this.transaction.payerOrgUser && this.transaction.payerOrgUser?.currencyCode != this.transaction.currencyCode;
    },
    foreignAmountStr() {
      return this.usesForeignAmount ? formatCurrency(this.transaction.payerOrgUser.amount, this.transaction.payerOrgUser.currencyCode) : null;
    }
  },
  methods: {
    showDropDown() {
      try {
        document.getElementById("myDropdown").classList.toggle("show");
      } catch (e) {
        console.log(e);
      }
    },
    logout() {
      clearLocal("tzpay-token");
      clearLocal("tzpay-user");
      this.tzpayToken = null;
      let flowId = this.fid;
      if (flowId) {
        flowId = '?rid=' + flowId;
      } else {
        flowId = '';
      }
      this.$router.replace('/' + flowId);
    },
    countryChanged(params) {
      console.log(params);
      // this.user.country = params.name;

    },
    phoneValidation(params) {
      if (params.valid) {
        this.phoneIsValid = true;
        // //console.log("Phone valid");
      } else {
        this.phoneIsValid = false;
        //  //console.log("Phone invalid");
      }

    },
    async cancelClicked() {
      return await this.$router.replace({ name: this.$route.meta?.prev });
    },

    sendOTPCode() {
      //console.log("send OTP code");

      var curPhone = this.phoneNumber.replace(/\s+/g, '');
      console.log(this.curPhone);

      if (this.phoneIsValid) {
        sendSMSOTP(curPhone)
          .then(res => {
            //console.log("res------send------", res)

            // Check response
            if (res.success && res.data) {
              this.showOTP = true
            }
          })
          .catch(err => {
            //console.log('=========error=======', err);
            clearInterval(this.intervalId);
            Notify({
              duration: 5000,
              message: this.$t('anErrorOccurredWhileProcessingTryRefresh'),
              type: 'danger'
            });
          })
      } else {
        Notify({
          duration: 5000,
          message: 'The phone umber is not valid!',
          type: 'warning'
        });
      }

    },
    loginSuccessful(result) {
      setLocal("tzpay-token", result.token);
      this.tzpayToken = result.token;
      this.currentUser = result.user;
      this.showPopUp = false;
    },

    verifyOTPCode() {
      //console.log("verify OTP code");
      var curPhone = this.phoneNumber.replace(/\s+/g, '');
      console.log(curPhone);

      verifySMSOTP(curPhone, this.OTPCode)
        .then(res => {
          //console.log("res------send------", res)

          // Check response
          if (res.success && res.data) {
            Notify({ type: 'success', message: 'Successfully logged in' });
            setLocal("tzpay-token", res.data.token);
            this.tzpayToken = res.data.token;
            this.currentUser = res.data.user;
            this.showPopUp = false;
          }
        })
        .catch(err => {
          //console.log('=========error=======', err);
          clearInterval(this.intervalId);
          Notify({
            duration: 5000,
            message: 'Incorrect OTP code',
            type: 'danger'
          });
        })
    },

    showFullDescription() {
      Notify({
        duration: 10000,
        color: 'white',
        background: '#922690',
        message: this.transaction.description,
      });
    },
    async continueClicked() {
      //console.log('tz-pay-clicked')
      if (this.tzpayToken != null) {
        setLocal("tzpay-user", this.currentUser);
      }

      if (this.$route.meta && this.$route.meta.next) {
        return await this.$router.replace({ name: this.$route.meta.next });
      }
    }
  }
}
</script>

<style scoped lang="scss">
* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
}

@media (max-width: 568px) {
  .wrapper {
    margin-top: -24px !important;
    padding: 24px !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    transform: none !important;
    height: 100% !important;
  }

  .modal {
    margin-top: -24px !important;
    padding: 24px !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    transform: none !important;
    height: 100% !important;
  }

  .tzp-table {
    margin-bottom: 20px;
  }

  .tz-payment__section_copyright {
    bottom: 30px !important;
  }
}

.container {
  /*position: absolute;
  width: 100vw;
  height: 100vh;
  background: #eeeeee;
  overflow-y: auto;*/
}

.tzp-table {
  margin-top: 64px;
}

.container-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98%;
  overflow-y: hidden;
  .main-item{
    position: relative;
    overflow-y: auto;
    bottom: 0;
    top: 0;
    position: absolute;
    background: white;
  }
}


.wrapper {
  position: relative;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  min-height: 400px;
  height: auto;
  padding: 34px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // box-shadow: 8px 8px 8px 8px #e1e1e1;
  // box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.title,
.title * {
  text-align: center;
}

.title h1 {
  // color: #141867;
  color: rgb(146, 38, 144);
  padding: 14px 0;
  font-size: 24px;
}

.title .description {
  color: #363771;
  font-size: 16px;
}

.auth-text {
  font-size: 12px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  flex-wrap: wrap;
  position: absolute;
  right: 44px;
  color: #606060;
  top: 20px;
}

.login-text {
  padding: 20px;
  color: #363771;
  font-size: 12px;
  margin-top: 20px;
  flex-wrap: wrap;
  left: 44px;
  color: #606060;
  bottom: 8px;
}

.text-sigin {
  padding-left: 2px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  grid-gap: 8px;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;

  &>* {
    width: 50%;
  }

  .cancel {
    color: #ee0a24;
    cursor: pointer;
    margin-top: 12px;
    text-align: center;
    width: 100%;

    &:hover {
      // font-weight: bold;
      text-decoration: underline;
    }
  }
}

/* Dropdown Button */
.link {
  float: left;
  font-size: 12px;
  color: white;
  text-align: center;
  padding: 5px 5px;
  text-decoration: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  cursor: pointer;
  font-size: 8px;
  border: none;
  outline: none;
  color: white;
  padding: 0px 20px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn,
.dropbtn:focus {
  color: rgb(146, 38, 144);
  ;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: rgb(57, 54, 54);
  padding: 2px 4px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
