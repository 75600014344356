<template>
  <div style="padding-top: 10px; text-align: center;" v-if="renderContent">
    <small v-if="showDownload" style="transform: scale(1) !important;">
      {{ $t('click') }} <a href="https://tranzak.net/#download">{{ $t('here') }}</a> {{ $t('toDownloadTheMobileApp') }}
    </small>
    <div class="tz-payment__section_copyright text-center py-5" style="font-size: 11px; transform: scale(.9);">
      {{ trans.poweredBy }}&nbsp;<a href="https://tranzak.net" target="_blank" title="Tranzak">Tranzak</a>
    </div>
  </div>
</template>

<script>
import websdkStore from '../../websdk.store';

export default {

  data() {
    return {
      trans: websdkStore.trans.en,
    }
  },
  props: {
    showDownload: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    renderContent(){
      if(websdkStore && websdkStore.paymentFlow && !websdkStore.paymentFlow.customization){
        return true
      }
      return false
    }
  },
  mounted(){
    this.trans = websdkStore.trans[websdkStore.lang];
  },
  mixins: [],

}
</script>

<style>
a {
  color: #922690;
}
</style>