<template>
  <div class="tz-payment__pm-type tz-selectable" :class="{ 'selected': selected }">
    <span class="badge" v-show="selected">
      <tz-icon style="margin-top: 4px; position: absolute;" name="success" color="white"></tz-icon>
    </span>
    <template v-for="(logo, idx) in logos">
      <span v-if="logo.isSvg" v-html="logo.src" :key="'svg_' + idx"></span>
      <img v-else :key="idx" :src="logo.src" :alt="logo.alt" :class="{ 'no-border bg-transparent': logo.styled}">
      <div v-if="logo.title" :key="idx">{{ $t(logo.title) }}</div>
    </template>
  </div>

</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    logos: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss" scoped>
.tz-payment__pm-type {
  display: flex;
  padding: 8px;
  height: 72px;
  border: 2px solid #E1E0E1;
  background: #EEEEEE;
  border-radius: 4px;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
}

.tz-payment__pm-type.last {
  margin: 0;
}

.tz-payment__pm-type img,
.tz-payment__pm-type svg {
  background: #e8e7e7;
  width: 68px;
  height: 40px;
  border: 1px solid #cecdcd;
  border-radius: 4px;
  margin: 4px;
}

.tz-selectable>.badge {
  width: 24px;
  height: 24px;
  right: -12px;
  top: -12px;
}
</style>