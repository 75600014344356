<template>
  <div>
 
    <form onsubmit="onSubmit(event)" class="content-area">
      <div>
        <input name='code' type="number" :class="`code-input pin-inp-${codeName}`" required/>
        <input name='code' type="number" :class="`code-input pin-inp-${codeName}`" required/>
        <input name='code' type="number" :class="`code-input pin-inp-${codeName}`" required/>
        <input name='code' type="number" :class="`code-input pin-inp-${codeName}`" required/>
        <input name='code' type="number" :class="`code-input pin-inp-${codeName}`" required/>
        <input name='code' type="number" :class="`code-input pin-inp-${codeName}`" required/>
      </div>
      <!-- <p><a href='#'>Resend Code</a></p>
      <input type="submit" value="Submit"> -->
  </form>

  </div>
</template>

<script>

export default {
  props: {
    codeName:{
      type: String,
      default: 'code'
    }
  },
  data() {
    return {
      inputElements: [],
    }
  },

  mounted() {
    console.log(this.codeName)
    //console.log("Le timeout s'exécutant en dernier");
    this.inputElements = [...document.querySelectorAll('input.pin-inp-' + this.codeName)]

    this.inputElements.forEach((ele,index)=>{
      ele.addEventListener('keydown',(e)=>{
        // if the keycode is backspace & the current field is empty
        // focus the input before the current. Then the event happens
        // which will clear the "before" input box.
        if(e.keyCode === 8 && e.target.value==='') this.inputElements[Math.max(0,index-1)].focus()

      })
      ele.addEventListener('input',(e)=>{
        // take the first character of the input
        // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
        const [first,...rest] = e.target.value
        e.target.value = first ?? '' // first will be undefined when backspace was entered, so set the input to ""
        const lastInputBox = index===this.inputElements.length-1
        const didInsertContent = first!==undefined
        if(didInsertContent && !lastInputBox) {
          // continue to input the rest of the string
          this.inputElements[index+1].focus();
          this.inputElements[index+1].value = rest.join('');
          this.inputElements[index+1].dispatchEvent(new Event('input'));
        }

        const code = this.inputElements.map(({value})=>value).join('')
        //console.log("Pin in component",code);
        this.$emit('onUpdatePin',code);
      })
    })
  },

  methods: {
    onSubmit(e){
      e.preventDefault()
      const code = inputElements.map(({value})=>value).join('')
      console.log(code)
    }
  },

}
</script>

<style scoped lang="scss">
  *,
::before,
::after {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  margin: 0;
}

:root {
  --spacing: 8px;
  --hue: 400;
  --background1: hsl(214, 14%, 20%);
  --background2: hsl(214, 14%, 13%);
  --background3: hsl(214, 14%, 5%);
  --brand1: hsl(var(--hue) 80% 60%);
  --text1: hsl(0,0%,100%);
  --text2: hsl(0,0%,90%);
}

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://fms.tranzak.me/cdn/pf/qcsn24qi7av3nob0_105151.02617418088password.ttf);
}


.code-input{
  font-family: 'password';
}

.code-input::-webkit-outer-spin-button,
.code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.code-input {
  -moz-appearance:textfield;
}

code {
  background: var(--background3);
}

body {
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  min-height: 100vh;
  background: var(--background1);
  // flex-gap: var(--spacing);
  color: var(--text1);
  gap: var(--spacing);
  padding: calc(var(--spacing) * 2);
  font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 1rem;
  }
}

a {
  color: var(--brand1);
  text-decoration: none;
}

.number-code {
  // overflow: auto;
  > div {
    display: flex;
    > input:not(:last-child) {
      margin-right: calc(var(--spacing) * 2);
    }
  }
}

.content-area {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 2);
  background: var(--background2);
  padding: var(--spacing);
  border-radius: var(--spacing);
  p {
    color: var(--text2);
    font-size: .8em;
  }
}

form {
  background-color: grey;
  input.code-input {
    font-size: 1.5em;
    width: 1.5em;
    text-align: center;
    flex: 1 0 1em;
    border: 1px solid;
    padding: 2px;
    border-radius: 4px;
    margin: 4px;
    border-color: #990099;
  }
  input[type='submit']{
    margin-left: auto;
    display: block;
    font-size: 1em;
  }
  input{
    padding: var(--spacing);
    border-radius: calc(var(--spacing) / 2);
    color: var(--text1);
    background: var(--background1);
    border: 0;
    border: 4px solid transparent;
    &:invalid {
      box-shadow: none;
    }
    &:focus{
      outline: none;
      border-width: 2.5px;
      box-shadow: 0px 0px 15px 5px #99009966;
      background: var(--background3);
    }
  }
}



</style>