<template>
  <div v-if="totalAmount" class="transaction-line">
    <div class="d-inline-block pr">
      {{ $t('amount') }}:
    </div>
    <div class="d-inline-block pl text-primary transaction-amount">
      {{ formatCurrency(totalAmount, currency) }}
    </div>
    <div class="d-flex" :style="isMobile? 'justify-content: left;':''">
      <div class="pr">
        {{ $t('description') }}:
      </div>
      <div style="font-weight: 500;">{{ paymentDescription }}</div>
    </div>
  </div>
</template>
<script>
  import websdkStore from '../../websdk.store';
  import { formatCurrency } from '../../utils';

  export default{
    name: 'TransactionAmount',
    data(){
      return {
        formatCurrency
      }
    },
    computed: {
      totalAmount(){
        if(websdkStore && websdkStore.paymentInfo && websdkStore.paymentInfo.amount){
          return websdkStore.paymentInfo.amount
        }
        return null;
      },
      isMobile(){
        return websdkStore.isMobile || false;
      },
      paymentDescription(){
        if(websdkStore && websdkStore.paymentInfo && websdkStore.paymentInfo.description){
          return websdkStore.paymentInfo.description
        }
        return null;
      },
      currency(){
        return websdkStore?.paymentInfo?.currencyCode
      }
    }
  }
</script>
<style>
  .transaction-amount{
    font-size: 20px;
    font-weight: bold;
  }
  .transaction-currency{
    color: black;
    font-size: 11px;
  }
  .transaction-line{
    border-bottom: 1px solid #ddd;
    padding-bottom: 7px;
    text-align: left;
  }
</style>