<template>
  <div class="card-user-info__container">
  
    <template v-if="step == CARD_STEPS.CHOOSE_PM">
      <div style="height: 300px; overflow-y: scroll ">
        <payment-method-list :mode="mode" @pm-selected="handlePmSelection" :items="banks" @country-changed="handleCountryChange" />
      </div>
      <div :style="{ gridTemplateColumns: isMobile ? '1fr 1fr' : 'fr' }" style="display: grid; grid-gap: 8px;">
        <tz-button v-if="isMobile" type="danger" plain :text="$t('back')" @click="backToList"></tz-button>
        <tz-button v-if="tokenAvailable" color="#922690" type="primary" :text="$t('submit')" :loading="loading" :disabled="!(userInfo.operator?.accountId)" @click="submitClicked" />
        <tz-button v-else color="#922690" type="primary" :text="$t('next')" :disabled="!(userInfo.operator?.accountId)" @click="nextClicked" />
      </div>

    </template>

    <template v-else>
      <div style="padding-top: 12px;">
        <div v-if="!tokenAvailable">
          <span style="font-weight: 100; font-size: .76rem; padding-bottom: 5px;">{{ $t('email') }}</span>
          <tz-field style="margin-bottom: 8px;" placeholder="example@domain.com" autofocus required clearable
            left-icon="envelop-o" v-model="userInfo.email" />
        </div>

          <!-- <span style="font-weight: 100; font-size: .76rem; padding-bottom: 5px;">{{ $t('phoneNumber') }}:</span>
          <vue-tel-input style="color:#323233; padding: 5px;" class="vue-tel-input-custom p-2"  placeholder="+237 6XX XXX XXX"  id="acMobile" v-model="userInfo.phone"
                          v-on:country-changed="countryChanged" v-on:validate="phoneValidation" v-bind="bindProps">
            <template slot="arrow-icon">
              <span class="vti__dropdown-arrow">&nbsp;▼ </span>
            </template>
          </vue-tel-input> -->

      </div>

      <div v-if="isVisa">
        <div v-if="isMobile" style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 8px;">
          <tz-button  type="danger" plain :text="$t('back')" @click="backToList"></tz-button>
          <tz-button  type="primary" color="#922690" :loading="loading" :disabled="!isValidForm()" :text="$t('submit')" @click="submitClicked" />
        </div>
        <div v-else>
          <tz-button type="primary" block color="#922690" :loading="loading" :disabled="!isValidForm()" :loading-text="$t('processing')" :text="$t('submit')" @click="submitClicked" />
        </div>
      </div>

      <div v-else style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 8px;">
        <tz-button type="danger" plain :text="trans.backForm" @click="prevClicked"></tz-button>
        <tz-button type="primary" color="#922690" :loading="loading" :disabled="!isValidForm()" :text="$t('submit')" @click="submitClicked"></tz-button>
      </div>
      <!-- <tz-button type="primary" :disabled="!isValidForm()" color="#922690" @click="submitClicked" :text="$t('submit')">
      </tz-button> -->
    </template>
  </div>
</template>

<script>
import PaymentMethodList from './PaymentMethodList.vue';
import { validateEmail, REGEXP_DEFAULT_PHONE, isMobile } from '../../utils';
import { createTransaction, updateTransaction } from '../../api';
import {VueTelInput} from 'vue-tel-input';
import userInfoMixin from '../../mixins/user-info.mixin';
import websdkStore from '../../websdk.store';
import { Notify } from 'tzui-vue';
import { getLocal } from '../../../../../utils';

const CARD_STEPS = {
  CHOOSE_PM: 1,
  ENTER_INFOS: 2
};

export default {
  components: {
    PaymentMethodList,
    VueTelInput
  },
  props: {
    pmMethods: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: "country"
    },
    isVisaMasterCard: {
      type: Boolean,
      default: false
    }
  },
  mixins: [userInfoMixin],
  data() {
    return {
      loading: false,
      tokenAvailable: false,
      banks: {},
      bindProps: {
        autocomplete: false,
        mode: "international",
        validCharactersOnly: true,
        inputOptions: {
          placeholder: ["Mobile"],
          required: true,
          styleClasses: ['form-control']
        },
        dropdownOptions:{
          disabled: true
        }
      },
      step: CARD_STEPS.CHOOSE_PM,
      CARD_STEPS,
      userInfo: websdkStore.userInfo,
      trans: websdkStore.trans.en,
      isMobile: isMobile(),
      isVisa: false,
      phoneIsValid: false,
    }
  },
  watch: {
    isVisaMasterCard(data){
      if(data){
        this.submitClicked();
        this.submittingPmEvent(true);
      }
    }
  },
  mounted(){
    const token = getLocal('tzpay-token');
    if(token){
      this.tokenAvailable = true;
    }

    this.trans = websdkStore.trans[websdkStore.lang];

    var user = getLocal("tzpay-user");
    if (user != undefined) {
      var objUser = user;
      //console.log("User found");
      this.userInfo.email = objUser.email;
      this.userInfo.phone = objUser.phone;
    } else {
      //console.log("No user");
    }

    if(this.mode == "currency"){
      this.isVisa = true;
      this.step = CARD_STEPS.ENTER_INFOS;
    }

    try{
      if(this.mode != "currency"){
        const banks = this.pmMethods['CM'].filter(item=> item.accountType == 3);
        this.banks = {...this.pmMethods, 'CM': banks};
      }else{
        //console.log("pms:   ",this.pmMethods);
        this.banks = this.pmMethods;

        if(!this.isMobile && this.tokenAvailable){
          this.submitClicked();
        }
      }
    }catch(e){
      //console.log("error here: ",e);
      this.banks = this.pmMethods;
    }

    // this.submitClicked();
  },

  methods: {
    submittingPmEvent(value = false){
      this.$emit('submitting', value);
    },
    countryChanged(params) {
      console.log(params);
      this.country = params.name;
    },

    phoneValidation(params) {
      if (params.valid) {
            this.phoneIsValid = true;
            //console.log("Phone valid");
        } else {
            this.phoneIsValid = false;
            //console.log("Phone invalid");
        }
    },
    handleCountryChange(country) {
      this.country = country;
    },
    isValidForm() {
      // return (validateEmail(this.userInfo.email) && this.phoneIsValid);
      return (validateEmail(this.userInfo.email) || this.tokenAvailable);
    },
    nextClicked() {
      this.step = CARD_STEPS.ENTER_INFOS;
      this.addCountryCodeToUserPhone();
    },
    prevClicked() {
      this.step = CARD_STEPS.CHOOSE_PM;
      this.removeCountryCodeToUserPhone();
    },
    handlePmSelection(value) {
      //console.log("handlePmSelection", value);
      this.userInfo.operator = value;
      websdkStore.paymentMethod = value;
    },
    backToList() {
      this.$emit('prev-clicked');
    },
    processTransaction(res){
      this.submittingPmEvent();
      this.loading = false;
      if (res && res.success && res.data) {
        websdkStore.paymentTransaction = res.data;
        websdkStore.paymentInfo = res.data;
        websdkStore.pipType = 'card';
        this.$router.replace({ name: "confirm-tnx" });
      } else {
        Notify({
          type: 'danger',
          duration: 5000,
          message: res.errorMsg,
        });
      }
    },
    failedTransaction(err){
      this.submittingPmEvent();
      this.loading = false;
      console.log(err);
      Notify({
        type: 'danger',
        duration: 5000,
        message: this.$t('anErrorOccurredWhileProcessingPleaseRetry'),
      });
    },
    submitClicked() {
      this.submittingPmEvent(true);
      this.loading = true;

      if( websdkStore && websdkStore.paymentTransaction && websdkStore.paymentTransaction._id && websdkStore.paymentTransaction.status != -40 && !websdkStore.paymentTransaction.disablePayerAccountSelection){ 
        updateTransaction(
          websdkStore.paymentTransaction._id, 
          this.pmMethods["XAF"]?.accountId || websdkStore.paymentMethod?.accountId, 
          undefined,
          this.userInfo?.email ?? undefined,
          ).then(res=>{
          this.processTransaction(res);
        }).catch(err=>{
          this.failedTransaction(err);
        });
        return;
      }

      createTransaction(
        websdkStore.paymentFlow.flowId,
        this.pmMethods["XAF"]?.accountId || websdkStore.paymentMethod?.accountId,
        // this.userInfo?.phone?.startsWith(this.userInfo.operator.code) ? this.userInfo.phone : (this.userInfo.operator.code || '') + this.userInfo.phone ?? "",
        null,
        this.userInfo?.email ?? "",
        window.location.origin +'/pred/'+websdkStore.paymentFlow.flowId
      )
        .then((res) => {
          this.processTransaction(res);
        }).catch(err => {
          this.failedTransaction(err);
        });
    }
  },
}
</script>

<style scoped>
.card-user-info__container {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 44px;
}
</style>