<template>
  <div>
    <TzPayWebMobile v-if="isMobile" :allPaymentMethods="websdkStore.allPaymentMethods"/>
    <TzPayWeb v-else :allPaymentMethods="websdkStore.allPaymentMethods"/>
    <!-- <keep-alive> -->
      <!-- <component :is="componentName" /> -->
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import { isMobile } from '../../../../../utils';
import TzPayWeb from './TzPayWeb.vue';
import TzPayWebMobile from './TzPayWebMobile.vue';
import websdkStore from '../../websdk.store';

export default {
  components: {
    TzPayWeb,
    TzPayWebMobile
  },
  data() {
    return {
      websdkStore,
      isMobile: isMobile(),
      // componentName: isMobile() ? TzPayWebMobile.name : TzPayWeb.name
    }
  }
}
</script>

<style>
</style>