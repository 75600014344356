<template>
  <div class="container">
    <div class="wrapper">
      <template v-if="transactionPending">
        <!-- <div class="loader mb-64"></div> -->
        <div class="message">{{ $t('pleaseWaitWhileThingsGetReady') }}</div>
      </template>
      <template v-else>
        <div class="icon">
          <svg width="150" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 71.577 71.577"
            style="enable-background:new 0 0 71.577 71.577; background-color: transparent; color: blueviolet;"
            xml:space="preserve">
            <g>
              <g>
                <polygon style="fill:#CCC;" points="46.525,57.523 25.052,57.523 17.894,64.681 53.683,64.681 		" />
                <polygon style="fill:#CCC;" points="42.295,6.896 42.27,6.939 41.461,16.065 49.546,29.304 39.5,15.554 41.568,7.763
			36.018,14.351 43.243,35.835 34.561,33.809 40.709,48.314 26.924,28.631 36.318,28.985 26.312,16.989 29.085,6.896 0,6.896
			0,53.422 71.577,53.422 71.577,6.896 		" />
              </g>
            </g>
          </svg>
        </div>
        <template v-if="triesExceeded">
          <p class="message">{{ $t('maxRetriesExceeded') }}.</p>

          <tz-button plain type="danger" text="Go back" @click="goBack" />
        </template>
        <template v-else>
          <p class="message">{{ $t('somethingWentWrongWhileInitializing') }}<br />
            {{ $t('pleaseRetryInFewMinutes') }}.</p>

          <tz-button plain type="danger" :text="$t('retry')" @click="loadTransaction" />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { Notify } from 'tzui-vue';
import { getPaymentRequest, getTransaction } from '../../api';
import store from '../../websdk.store';
import websdkStore from '../../websdk.store';
import { getLocal,setLocal } from '../../../../../utils';

const FLOW_STATUSES = Object.freeze({
  STATUS_PENDING: 1,
  STATUS_PAYMENT_IN_PROGRESS: 2,
  STATUS_COMPLETED: 3,
  STATUS_FAILED: -1,
  STATUS_CANCELLED: -2
});

export default {
  data() {
    return {
      id: null,
      transactionPending: true,
      tryCount: 4,
      triesExceeded: false,
      canNavigate: false
    };
  },
  mounted() {
    const url = new URL(window.location.href);

    let id = url.searchParams.get('rid');

    const token = url.searchParams.get('token');

    if (token != null) {
      setLocal("tzpay-token", token);
    }
    if(!id){
      id = getLocal('tzpay-rid');
    }

    const exitingToken = getLocal("tzpay-token");

    this.id = id || store.paymentRequest?.id;

    //console.log("tzpay-token from storage", exitingToken);

    this.loadTransaction();
  },
  watch: {
    canNavigate: {
      immediate: true,
      handler: function (newVal, oldVal) {
        //console.log('watch canNavigate', { newVal, oldVal });
        if (newVal == true) {
          this.$router.replace({ name: "select-mode", params: store.paymentFlow })
            .catch(err => console.error(err));
        }
      }
    }
  },
  methods: {
    goBack() {
      this.$router.replace('/');
    },
    loadTransaction() {
      this.transactionPending = true;
      this.tryCount -= 1;

      const that = this;

      getPaymentRequest(this.id)
        .then((res) => {
          if (res && res.success && res.data) {
            if (Array.isArray(res.data)) {
              if (res.data.length <= 0)
                throw new Error(res.errorMsg);
              else
                store.paymentFlow = res.data[0];
            } else {
              store.paymentFlow = res.data;
            }

            const { status, transactionId } = store.paymentFlow;

            if(store.paymentFlow.approvalRequired && !store.paymentFlow.isApproved){
              return this.$router.replace('/approval-required');
            }

            if (!Object.values(FLOW_STATUSES).includes(status)) {
              throw new Error(res.errorMsg || '[TZP]: Item Not found');
            }

            switch (status) {
              case FLOW_STATUSES.STATUS_COMPLETED:
              case FLOW_STATUSES.STATUS_FAILED:
              case FLOW_STATUSES.STATUS_CANCELLED:
                if(transactionId){
                  getTransaction(transactionId)
                    .then((txRes) => {
                      let tx = {};
                      if (txRes && txRes.success && txRes.data) {
                        if (Array.isArray(txRes.data)) {
                          if (txRes.data.length <= 0) {
                            throw new Error(txRes.errorMsg || this.$t('errorWhenLoadingPaymentDetails'));
                          } else {
                            tx = txRes.data[0];
                          }
                        } else {
                          tx = txRes.data;
                        }
  
                        websdkStore.paymentResult = tx;
                        websdkStore.canRedirect = false;
  
                        return this.$router.replace({ name: status > 0 ? 'success' : 'failure' });
  
                      } else {
                        throw new Error(txRes.errorMsg || this.$t('errorWhenLoadingPaymentDetails'));
                      }
                    })
                    .catch((txError) => {
                      Notify({
                        message: txError.message || txError.errorMsg || this.$t('errorWhenLoadingPaymentDetails'),
                        type: '#922690',
                        duration: 5000
                      });
                      this.transactionPending = false;
                      this.triesExceeded = this.tryCount <= 0;
                    });
                }else{
                  return this.$router.replace({name:'payment-cancelled'});
                }


                break;

              default:
                store.paymentInfo = store.paymentFlow;
                that.canNavigate = true;
                break;
            }

            return;
          }else{
            Notify({
              message: res.errorMsg || this.$t('paymentNotFoundOrAlreadyCompleted'),
              color: 'warning',
              duration: 5000
            });
          }
          throw new Error( res.errorMsg || '[TZP]: Item Not found');
        })
        .catch(err => {
          Notify({
            message: err.message,
            color: 'danger',
            duration: 5000
          });
          this.transactionPending = false;
          this.triesExceeded = this.tryCount <= 0;
        });
    }
  }
}
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: #d6cece46;
}

.wrapper {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message {
  color: #922690;
  text-align: center;
  font-style: italic;
  font-size: medium;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media (max-width: 568px) {
  .container{
    height: 100vh !important;
  }
}
</style>