<template>
  <div class="tz-payment__section_form_body__method-list_container">
    <template v-if="!useCurrencies">
      <h4 class="mb-0">{{ trans.paymentMethods }}</h4>
      <div class="tz-payment__section_form_body__method-list">
        <div style="margin-bottom: 40px;" class="currency-selector">
          <vue3-country-selector @changed="countryChanged" style="display: flex; align-items: center; padding-right: 10px !important;" :country="defaultCountry"
            :codes="countries">
          </vue3-country-selector>
        </div>

        <tz-row :wrap="true" justify="space-between" gutter="8">
          <tz-col v-for="(item, idx) in pms" :key="idx">
            <payment-method style="margin: 4px;" @click="selectPaymentMethod(item)" :logo="item.logo"
              :selected="item.selected">
            </payment-method>
          </tz-col>
        </tz-row>
      </div>
    </template>
    <template v-else>
      <h4 class="mb-0">{{ trans.chooseDesiredCurrency }}</h4>
      <div class="tz-payment__section_form_body__method-list">
        <div style="margin-bottom: 40px;">
          <vue3-selector @changed="currencyChanged" :items="currencies" :selected-value="defaultCurrency">
          </vue3-selector>
        </div>
      </div>
    </template>
  </div>

</template>

<script>
import websdkStore from '../../websdk.store';
import PaymentMethod from './PaymentMethod.vue';
import { CMR_COUNTRY_CODE } from '../../utils';

export default {
  components: {
    PaymentMethod,
  },
  data() {
    return {
      countries: [],
      currencies: [],
      pms: [],
      userInfo: websdkStore.userInfo,
      trans: websdkStore.trans.en,
      useCurrencies: this.mode == 'currency'
    }
  },
  props: {
    items: {
      type: Object,
      default: () => ({})
    },
    selectedCountry: {
      type: String,
    },
    selectedCurrency: {
      type: String,
    },
    mode: {
      type: String,
      default: 'country'
    }
  },
  computed: {
    defaultCountry() {
      return this.selectedCountry || this.countries.includes(CMR_COUNTRY_CODE) ? CMR_COUNTRY_CODE : this.countries[this.countries.length - 1];
    },
    defaultCurrency() {
      return this.selectedCurrency || this.currencies.includes("XAF") ? "XAF" : this.currencies[this.currencies.length - 1];
    }
  },
  mounted() {
    this.trans = websdkStore.trans[websdkStore.lang];

    setTimeout(() => {
      if (this.useCurrencies) {
        const keys = Object.keys(this.items);
        const xafIdx = keys.findIndex(x => x.toUpperCase() == "XAF");

        if (xafIdx > -1) {
          const xaf = keys.splice(xafIdx);

          keys.unshift(...xaf);
        }
        // this.activeCountry = keys;
        this.currencies = keys;
      } else {
        const keys = Object.keys(this.items);
        const cmIdx = keys.findIndex(x => x.toUpperCase() == "CM");

        if (cmIdx > -1) {
          const cm = keys.splice(cmIdx);

          keys.unshift(...cm);
        }
        // this.activeCountry = keys;
        this.countries = keys;
      }
    }, 200);
  },
  watch: {
    useCurrencies: {
      immediate: false,
      handler: function (oldVal, newVal) {

      }
    }
  },
  methods: {
    selectPaymentMethod(item) {
      const selectedItem = this.pms.find(x => x && x.selected == true);

      if (selectedItem) {
        selectedItem.selected = false;
      }

      item.selected = true;

      this.$emit('pm-selected', item, this.mode);
    },
    countryChanged(country) {
      if (this.mode == "country") {
        this.pms = this.items[country.code];
        this.$emit('country-changed', country);
      }
    },
    currencyChanged(currency) {
      //console.log("currency : ",currency);
      this.$emit('pm-selected', this.items[currency], this.mode);
    }
  },
}
</script>

<style lang="scss" scoped>
.tz-payment__section_form_body__method-list_container {
  display: flex;
  flex-direction: column;
}

.tz-payment__section_form_body__method-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 210px;
  // overflow: auto;
  justify-content: center;
}

@media (max-width: 568px) {
  .tz-payment__section_form_body__method-list {
    justify-content: flex-start;
    max-height: fit-content;
    min-height: 300px;
  }
}
</style>
<style lang="scss">
  .currency-selector {
    .v3-selector_list{
      /* display: block !important; */
      margin-top: 150px !important;
    }
  }

  @media (max-width: 568px) {
    .currency-selector {
      .v3-selector_list{
        /* display: block !important; */
        margin-top: 150px !important;
      }
    }
  }
</style>