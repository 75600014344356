<template>
  <div style="height: 100vh; display: flex; justify-content: center; align-items: center;">
    <div class="payment-receipt">
        <div class="payment-info-details" id="payment-receipt-body" v-if="receipt.amount">
          <div class="div d-flex" style="align-items: center;">
            <div>
              <div style="padding-top: 5px;">
                <img :src="logo" v-if="renderLogo" height="50" style="margin-right: 8px; border-radius: 6px;">
              </div>
            </div>
            <div class="w-100">
              <span style="font-weight: 400; font-size: 1.4rem;">
                {{ merchant  }}
              </span>
              <div v-if="receipt.beneVerifiedName" style="font-weight: bold;"> <tz-icon name="checked" color="#990099" /> {{ receipt.beneVerifiedName }}</div>
              <div v-if="receipt.beneUserId">{{ receipt.beneUserId }}</div>
            </div>
          </div>
          <h1 class="text-center" style="font-size: 35px;">
            {{ formatCurrency(receipt.amount, receipt.currencyCode) }}<small style="color: #888; font-size: 15px;">{{ receipt.currencyCode }}</small>
          </h1>
          <div class="transaction-info-detail">
            <div class="text-grey">{{ $t('status') }}</div>
            <div class="description">
              <div class="w-100 text-capitalize" style="color: green">
                {{ $t('statusSuccessful') }}
              </div>
            </div>
          </div>
          <div class="transaction-info-detail">
            <div class="text-grey">{{ $t('fee') }}</div>
            <div class="description">
              <div class="w-100">
                {{ formatCurrency(receipt.payerFee, receipt.payerCurrencyCode) }} <small>{{ receipt.payerCurrencyCode }}</small>
              </div>
            </div>
          </div>
          <div class="transaction-info-detail">
            <div class="text-grey">{{ $t('description') }}</div>
            <div class="description">
              <div class="w-100">
                {{ receipt.description }}
              </div>
            </div>
          </div>
          <div class="transaction-info-detail">
            <div class="text-grey">{{ $t('transactionId') }}</div>
            <div class="description">
              <div class="w-100">
                {{ receipt._id }}
              </div>
            </div>
          </div>
          <div class="transaction-info-detail">
            <div class="text-grey">{{ $t('dateTime') }}</div>
            <div class="description">
              <div class="w-100" v-if="receipt.completedAt">
                {{ toDateTime(receipt.completedAt) }}
              </div>
            </div>
          </div>
          <div class="transaction-info-detail">
            <div class="text-grey text-right" style="margin">{{ $t('paymentMethod') }}</div>
            <div class="description" :style="`justify-content: end !important;`">
              <div>
                <!-- <img :src="receipt.paymentMethodServiceAvatarUrl" height="45" style="margin-right: 8px; border-radius: 6px;"> -->
                <img v-if="renderPaymentMethod" :src="paymentMethod" height="45" style="margin-right: 8px; border-radius: 6px;">
              </div>
              <div class="">
                {{ receipt.paymentMethodService }}
                <div>{{ receipt.paymentMethodAccountId }}</div>
                <div>{{ receipt.paymentMethodAccountName }}</div>
              </div>
            </div>
            <div style="padding-top: 15px;">
              <copyright :showDownload="true" />
            </div>
          </div>
        </div>
        <div v-if="failedToLoad" style="padding: 20px" class="text-center">
          <div style="margin: 50px 20px;" class="text-primary">
            {{ $t('failedToLoadReceiptDescription') }}
          </div>
          <tz-button color="#990099" round size="small" style="padding-left: 50px; margin-bottom: 10px; padding-right: 50px;" @click="getReceipt()">{{ $t('tryAgain') }}</tz-button>
        </div>
        <div class="sm-block-md-flex mt-10">
          <div class="text-center w-100" style="padding: 4px;" v-if="receipt.amount">
            <tz-button color="#990099" round size="small" plain style="padding-left: 20px; padding-right: 20px;" @click="downloadReceipt()">{{ $t('downloadAsImage') }}</tz-button>
          </div>
          <div class="text-center w-100" style="padding: 4px;" v-if="receipt.amount">
            <tz-button color="#990099" round size="small" plain style="padding-left: 20px; padding-right: 20px;" @click="downloadReceipt(2)">{{ $t('downloadAsPdf') }}</tz-button>
          </div>
          <div class="text-center w-100" style="padding: 4px;" v-if="transaction.returnUrl">
            <tz-button color="#990099" round size="small" style="padding-left: 20px; padding-right: 20px;" @click="goToReturnUrl">{{ $t('goBackToMerchant') }}</tz-button>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import websdkStore from '../websdk/websdk.store';
import UserInfo from '../websdk/Components/shared/UserInfo.vue';
import { formatCurrency } from '../../../utils';
import Copyright from '../websdk/Components/shared/Copyright.vue';
import { getReceipt } from './api.js';
import { toDateTime } from '../websdk/utils';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas'
const doc = new jsPDF({
  orientation: "portrait",
  unit: "px",
});

export default {
  components: {
    UserInfo,
    Copyright
  },
  data() {
    return {
      transaction: {},
      logo: '',
      failedToLoad: false,
      paymentMethod: '',
      toDateTime,
      renderPaymentMethod: false,
      renderLogo: false,
      receipt: {},
      formatCurrency,
      autoRedirect: 0,
      intervalId: null,
    };
  },
  watch: {
    autoRedirect(data){
      if(data <=0){
        this.goToReturnUrl();
        clearInterval(this.intervalId);
      }
    },
    logo(data){
      this.renderLogo = true;
    },
    paymentMethod(data){
      this.renderPaymentMethod = true;
    }
  },
  mounted() {
    this.transaction = JSON.parse(this.$route.params.transaction);
    this.getReceipt(this.$route.params.transactionId);
  },
  computed: {
    merchant() {
      let name = null
      if(this.flow.customization && this.flow.customization.title){
        name = this.flow.customization.title
      }else if(this.flow.app && this.flow.app.name){
        name = this.flow.app.name;
      }
      name = name || this.receipt.beneDisplayName;
      return name;
    },
    merchantImage() {
      if(this.flow.customization && this.flow.customization.logoUrl){
        return this.flow.customization.logoUrl
      }
      if(this.flow.app && this.flow.app.avatarUrl){
        return this.flow.app.avatarUrl;
      }
      if(this.receipt.beneAvatarUrl && this.receipt.beneAvatarUrl){
        return this.receipt.beneAvatarUrl;
      }
      if(this.transaction.beneUser && this.transaction.beneUser?.avatarUrl){
        return this.transaction.beneUser.avatarUrl;
      }
      return this.receipt.beneAvatarUrl;
    },
    flow(){
      return websdkStore.paymentFlow || websdkStore.paymentInfo || {};
    },
    displayItems(){
      return [
        {key: 'flowId', value: this.transaction.flowId, image: ''},
        {key: 'description', value: this.transaction.description, image: ''},
        {key: 'merchant', value: this.transaction.description, image: this.flow.beneAvatarUrl || ''},
        {key: 'status', value: this.$t('cancelled'), image: ''},
      ]
    }
  },
  methods: {
    async processImages(){
      const logo = document.createElement('img');
      logo.src = this.receipt.paymentMethodServiceAvatarUrl;

      this.logo = await this.convertToBlob(this.merchantImage);
      this.paymentMethod = await this.convertToBlob(this.receipt.paymentMethodServiceAvatarUrl);
    },
    async getBase64Image(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async convertToBlob(image){
      try{
        const response = await fetch(image)
        const blob = await response.blob();
        return this.getBase64Image(blob);
      }catch(e) {
        console.log(e);
        return image
      }
    },
    generatePdf(image, canvas){

      const doc = new jsPDF('p', 'mm', 'a4');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) ;
      const marginY = (pageHeight - canvasHeight) / 2;

      doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
      // doc.output('dataurlnewwindow');
      doc.save(`Payment-receipt-${this.receipt._id}.pdf`);
    },
    downloadReceipt(mode = 1){ // 1 for image, 2 for pdf
      html2canvas(document.querySelector("#payment-receipt-body"), {useCORS: true, allowTaint: true, scale: 1.8}).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL("image/png");
        link.download =  `Payment-receipt-${this.receipt._id}.png`;
        if(mode == 1){
          link.click();
        }else{
          this.generatePdf(link.href, canvas)
        }
      });
    },
    getReceipt(transactionId){
      this.failedToLoad = false;
      this.receipt = {};
      getReceipt(transactionId).then(res=>{
        if(res.success){
          this.receipt = res.data;
          this.processImages()
        }else{
          this.failedToLoad = true;
        }
      }).catch(err=>{
        this.failedToLoad = true;
        console.log(err);
      });
    },
    goToReturnUrl(){
      let fullUrl = new URL(this.transaction.returnUrl);
      fullUrl = this.appendParamToQueryString(fullUrl, 'success', true);
      fullUrl = this.appendParamToQueryString(fullUrl, 'transactionId', this.receipt._id);
      fullUrl = this.appendParamToQueryString(fullUrl, 'requestId', this.transaction.flowId);
      window.location.replace(fullUrl);
    },
    appendParamToQueryString(url, name, value) {
      if (!url || !name) return url;

      return `${url}`.includes('?') ? `${url}&${name}=${value}` : `${url}?${name}=${value}`;
    },
  },
};
</script>

<style lang="less">
  html{
    background-color: #eee;
    height: 100vh;
  }
  .payment-receipt{
    max-width: 500px;
    padding: 10px;
    width: 100%;
    margin: auto;
  }
  .payment-info-details{
    border-radius: 5px;
    background-color: #fff;
    padding: 15px;
  }
  .user-info-details{
    padding-bottom: 15px;
    text-align: right;
  }
  .transaction-info-detail{
    border-top:1px solid #eee;
    padding: 7.5px 0px;
    .description{
      display: flex;
      align-items: center;
      font-weight: bold; color: #222;
    }
  }
</style>