
import { tzFetch, noSandBox} from "../../../utils/http";

//send OTP
export function sendSMSOTP (params = {}) {
  return tzFetch(`${noSandBox}/ba021/v1/sms/otp`, params, 'POST');
}
export function sendWhatsappOTP (params = {}) {
  return tzFetch(`${noSandBox}/ba021/v1/wa/otp`, params, 'POST');
}
export function sendEmailOTP (params = {}) {
  return tzFetch(`${noSandBox}/ba021/v1/send/email-otp`, params,'POST');
}

export function setPaymentPin (params = {}) {
  return tzFetch(`${noSandBox}/ba021/v1/payment/forgot-pin`, params, 'POST');
}
//verify OTP
export function verifySMSOTP (params = {}) {
  return tzFetch(`${noSandBox}/ba021/v1/login`, params, 'POST');
}
export function verifyEmailOTP (params = {}) {
  return tzFetch(`${noSandBox}/ba021/v1/email-sign-up-login`, params, 'POST');
}
