<template>
  <div class="text-center" style="padding-top: 10px; margin-bottom: 30px;">
    <tz-button size="small" @click="cancelTransaction" color="#eee" style="color: #333; border: 1px solid #999; border-radius: 6px;" plain type="dark">{{ $t('cancelTransaction') }}</tz-button>
  </div>
</template>
<script>
import { cancelFlow } from "./api.js";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { Dialog, Notify } from 'tzui-vue';
import websdkStore from '../websdk/websdk.store';
import PinInput from '../websdk/Components/shared/PinInput.vue';

export default {
  components: {
    VueTelInput,
    Notify,
  },
  props:{
    locale: {
      type: String,
      default: ()=> 'en',
    }
  },
  data() {
    return {
      
    };
  },

  mounted() {
  
  },
  computed: {
    showButton(){
      return this.$route && this.$route.name != 'success'
    }
  },
  methods: {
    cancelTransaction(){
      Dialog.confirm({
        message: this.$t('byCancellingThisTransactionYouWontBeAbleToProceedWithThisPayment'),
        cancelButtonText: this.$t('no'),
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#990099',
        confirm
      }).then(() => {
        const param = {
          flowId: websdkStore.paymentFlow.flowId
        }
        cancelFlow(param).then(res => {
          if (res.success && res.data) {
            return this.$router.replace({name:'payment-cancelled', params: {showAutoRedirect: true}});
          }
        })
        .catch(err => {
          Notify({
            duration: 5000,
            message: this.$t('failedToCancelTransaction'),
            type: 'danger'
          });
        })
      })
      .catch(() => {
        // on cancel
      });
    },
  },
};
</script>

<style lang="less" scoped>
.text-primary {
  color: #990099;

}

#su-email{
  display: block;
  width: 100%;
  border: 1px solid #ccc !important;
  border-radius: 5px;
  padding: 16px;
}
.switch-su-type{
  color: #990099;
  cursor: pointer;
}

.title,
.title * {
  text-align: center;
}

.title h1 {
  // color: #141867;
  color: rgb(146, 38, 144);
  padding: 14px 0;
  font-size: 24px;
}

.selected {
  border: 2px solid #990099;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.text-secondary {
  color: rgb(91, 87, 87);
}

.text-bold {
  font-weight: bold;
}

.top-content {
  height: 225px;
  display: flex;
  justify-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}


.heading {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin: 10px 20px;
  display: flex;
  justify-items: center;
  color: #fff;
}

.slogan {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: 5px 10px;
  color: #fff;
}

.additional-info {
  // padding: 10px;
  font-size: smaller;
  padding-top: 10px;
}

a {
  font-size: 13px;
}

.fade-leave-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.pr-10{
  padding-right: 10px;
}
.pt-10{
  padding-top: 10px;
}
.text-primary{
  color: #990099 !important;
}
.d-flex{
  display: flex;
}
.w-100{
  width: 100%;
}
.text-underline{
  text-decoration: underline;
}
.text-dark{
  color: #222;
}
.text-fade{
  opacity: .5;
}
</style>