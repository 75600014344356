<template>
  <div>
    <div style="width: 90vw; max-width: 500px" class="modal">
      <!-- Title -->
      <div class="title">
        <h1>{{ $t('forgotPaymentPin') }}</h1>
      </div>
      <div style="width: 300px; margin: auto;">
        <tz-form style="margin-left: 15px;margin-right: 15px;" class="" @submit="onSubmit">
          <div style="padding: 12px 0; display: flex; flex-direction: column; justify-content: center;">
            <div v-if="stage == stages.verifyAccount">
              <div style="font-weight: 100; font-size: 1rem; margin-top: 5px; margin-bottom: 8px;  margin-left: 10px;">
                {{$t('aOneTimePasswordWillBeSentTo') }}:
              </div>
              <tz-row style=" width: 100%;" justify="center" class="p-10">
                <!-- <tz-col span="6" class="primary" style="color:#646566"><span style="color:red">*</span>Phone number</tz-col> -->
                <tz-col ref="item2" span="24" v-if="recoveryMode == recoveryModes.phone">
                  <span class="text-primary">{{ userInfo.phone }}</span>
                </tz-col>
                <tz-col ref="item2" span="24" v-else>
                  <span class="text-primary">{{ userInfo.email }}</span>
                </tz-col>
                <tz-col span="24" ref="item3">
                  <div v-if="isInternational && recoveryMode == recoveryModes.phone" style="margin-top: 10px;">
                    <small>
                      {{ $t('otpWillBeSentThroughWhatsAppForInterNationalPhoneNumbers') }}
                    </small>
                  </div>
                  <div class="d-flex pt-10" v-if="userInfo.email && recoveryMode == recoveryModes.phone">
                    <div class="w-100 text-right">
                      <small class="switch-su-type pt-10" @click="changeRecoveryType(recoveryModes.email)">
                        {{ $t('useEmail') }}
                      </small>
                    </div>
                  </div>
                  <div class="d-flex pt-10" v-if="userInfo.phone && recoveryMode == recoveryModes.email">
                    <div class="w-100 text-right">
                      <small class="switch-su-type pt-10" @click="changeRecoveryType(recoveryModes.phone)">
                        {{ $t('usePhone') }}
                      </small>
                    </div>
                  </div>
                  <div style="margin: 16px;">
                    <tz-button round block type="primary" color="#922690" @click="sendOTPCode()" native-type="submit">
                      {{ $t('sendOTP') }}
                    </tz-button>
                  </div>
                </tz-col>
              </tz-row>
            </div>
            <div v-if="stage == stages.validateOtp">
              <div>
                <tz-button color="#990099" size="small" round plain @click="stage-=1"><tz-icon name="arrow-left" />{{ $t('back') }}</tz-button>
              </div>
              <div style="padding: 10px; padding-top: 0px; padding-bottom: 20px;">
                <small style="font-size: 12px; color: #000; margin-top: 10px; margin-bottom: 8px; display: block">
                  {{ $t(otpMessage) }} :
                </small>
                
                <div class="d-flex" style="align-items: center">
                  <div class="w-100">
                    <tz-field style="border: 3px solid #eeeeee; margin-bottom: 0px; height: 45px; line-height: 32px; width: 100%; border-radius: 4px; padding: 2px;" :maxlength="6" type="tel" autofocus clearable v-model="OTPCode" left-icon="coupon-o" placeholder="000000" />
                  </div>
                  <div style="padding-left: 20px;" class="w-100; text-right">
                    00:{{countDown > 9? countDown : `0${countDown}`}}
                  </div>
                </div>
                
                <div class="d-flex" style="white-space: nowrap !important; padding-bottom: 20px; cursor: pointer">
                  <div>
                    <small @click="resendOTPCode" :class="`${countDown == 0? 'text-primary':'text-fade'} text-underline`">{{ $t('resendOTP') }}</small>
                  </div>
                </div>
              
                <tz-button round block type="primary" color="#922690" @click="verifyOTPCode()" native-type="submit">
                  {{ $t('verityOtp') }}
                </tz-button>
              </div>
            </div>
            <div v-if="stage == stages.enterNewPin">
              <div>
                <tz-button color="#990099" size="small" round plain @click="stage-=1"><tz-icon name="arrow-left" />{{ $t('back') }}</tz-button>
              </div>

              <tz-row  style=" width: 100%;" justify="center" class="p-5">
                <span style="font-weight: 100; font-size: 1rem; margin-top: 8px; margin-bottom: 8px;margin-left: 10px; text-align: center;">
                  {{ $t('enterNewPin') }}</span>
                <pin-input @onUpdatePin="updatePin" :codeName="'new-pin'" v-if="renderPins" />
              </tz-row>
              
              <tz-row  style=" width: 100%;" justify="center" class="p-5">
                <span style="font-weight: 100; font-size: 1rem; margin-top: 8px; margin-bottom: 8px;margin-left: 10px; text-align: center;">
                  {{ $t('confirmNewPin') }}</span>
                <pin-input @onUpdatePin="updateConfirmPin" :codeName="'confirm'" v-if="renderPins" />
              </tz-row>

              <tz-row  style=" width: 100%; padding-bottom: 30px; padding-top: 20px;" justify="center" class="">
                <tz-button round block type="primary" color="#922690" @click="setPaymentPin()" native-type="submit">
                  {{ $t('setPaymentPin') }}
                </tz-button>
              </tz-row>
            </div>
          </div>

        </tz-form>
      </div>
    </div>
  </div>
</template>
<script>
import { sendEmailOTP, sendSMSOTP, sendWhatsappOTP, verifyEmailOTP, verifySMSOTP, verifyWhatsAppOTP, setPaymentPin } from "./api.js";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { Form, Field, Notify } from 'tzui-vue';
import websdkStore from '../websdk/websdk.store';
import PinInput from '../websdk/Components/shared/PinInput.vue';

export default {
  components: {
    VueTelInput,
    Notify,
    PinInput,
    Field,
    Form
  },
  props:{
    locale: {
      type: String,
      default: ()=> 'en',
    }
  },
  data() {
    return {
      renderPins: true,
      pin:'',
      confirmPin:'',
      stage: 1,
      stages: {
        verifyAccount: 1,
        validateOtp: 2,
        enterNewPin: 3,
      },
      recoveryMode: 1, // for email
      recoveryModes: {
        email: 1,
        phone: 2,
      },
      signupMethods: {
        phone: 1,
        email: 2,
        whatsapp: 3,
      },
      signupMethod: 1,
      tzLmToken: null,
      currentUser: {},
      phoneNumber: null,
      phoneIsValid: false,
      isInternational: false,
      otpMessages: {
        phone: 'enterOTPCodeSentToYourPhoneNumber',
        whatsapp: 'enterOTPCodeSentToYourWhatsApp',
        email: 'enterOTPCodeSentToYourEmail',
      },
      otpMessage: '',
      OTPCode: null,
      showOTP: false,
      showPopUp: false,
      email: '',
      countDown: 59,
      timer: null,
      lang: 'en'
    };
  },

  mounted() {
    if(websdkStore.userInfo.email){
      this.changeRecoveryType(this.recoveryModes.email);
    }else{
      this.changeRecoveryType(this.recoveryModes.phone);
    }
  },
  computed: {
    userInfo(){
      return websdkStore.userInfo || {}
    }
  },
  methods: {
    updatePin(newPin){
      this.pin = newPin;
    },
    updateConfirmPin(newPin){
      this.confirmPin = newPin;
    },
    changeRecoveryType(type = 2){
      this.recoveryMode = type;
      if(type == this.recoveryModes.phone){
        if(websdkStore.userInfo.phone.substring(0, 4) != '+237'){
          this.isInternational = true;
        }else{
          this.isInternational = false;
        }
      }else{
        this.isInternational = false;
      }
    },
    onSubmit(e){
      return false;
    },
    resendOTPCode(){
      if(this.countDown == 0){
        this.OTPCode = '';
        this.sendOTPCode();
      }
    },
    resetOTP(){
      this.showOTP = false;
      clearInterval(this.timer);
    },
    changeType(type = this.signupMethods.phone){
      this.signupMethod = type;
      this.showOTP = false;
      this.OTPCode = null;
    },
    countryChanged(params) {
      console.log(params);
      this.isInternational = false;
      if(params.iso2 != 'CM'){
        this.isInternational = true;
      }
      // this.user.country = params.name;

    },

    phoneValidation(params) {
      if (params.valid) {
        this.phoneIsValid = true;
        // //console.log("Phone valid");
      } else {
        this.phoneIsValid = false;
        //  //console.log("Phone invalid");
      }

    },
    sendOTPCode() {
      if(this.recoveryMode == this.recoveryModes.email){
        this.triggerOTP({email: this.userInfo.email}, sendEmailOTP);
      }else{
        this.triggerOTP({phone: this.userInfo.phone}, sendSMSOTP);
      }
    },
    triggerOTP(param, request){
      this.otpMessage = this.otpMessages.email;
      if(this.recoveryMode == this.recoveryModes.phone) {
        this.otpMessage = this.otpMessages.phone;
        if(this.userInfo.phone.substring(0,4) != '+237'){
          this.otpMessage = this.otpMessages.whatsapp;
          // request = sendWhatsappOTP;
        }
      }
      request(param).then(res => {
        //console.log("res------send------", res)

        // Check response
        if (res.success && res.data) {
          this.stage = this.stages.validateOtp;
          this.showOTP = true;
          this.countDown = 60;
          clearInterval(this.timer);
          this.timer = setInterval(()=>{
            this.countDown-=1;
            if(this.countDown == 0){
              clearInterval(this.timer);
            }
          }, 1000)
        }
      })
      .catch(err => {
        //console.log('=========error=======', err);
        clearInterval(this.intervalId);
        Notify({
          duration: 5000,
          message: this.$t('anErrorOccurredWhileProcessingTryRefresh'),
          type: 'danger'
        });
      })
    },
    reRenderPins(){
      this.renderPins = false;
      setTimeout(() => this.renderPins = true, 1000);
    },
    setPaymentPin() {
      if(this.pin != this.confirmPin){
        Notify({
          duration: 5000,
          message: this.$t('newPinAndConfirmPinDoNotMatch'),
          type: 'danger'
        });
        return;
      }
      
      let params = { pin: this.pin, code: this.OTPCode, phone: this.userInfo.phone };
      if(this.recoveryMode == this.recoveryModes.email){
        delete params.phone;
        params.email = this.userInfo.email
      }
      setPaymentPin(params).then(res => {
        if (res.success && res.data) {
          this.pin = '';
          this.OTPCode = '';
          this.stage = this.stages.verifyAccount
          this.reRenderPins();
          Notify({ type: 'success', message: this.$t('paymentPinChangedSuccessfully') });
          this.$emit('pin-changed');
        }else{
          Notify(res.errorMsg || this.$t('failedToChangePinTryAgain'));
        }
      }).catch(err => {
        Notify({
          duration: 5000,
          message: this.$t('failedToChangePinTryAgain'),
          type: 'danger'
        });
      })
    },
    verifyOTPCode() {
      if(this.OTPCode.length < 6){
        Notify({
          message: this.$t('enterValidOTP'),
          type: 'warning'
        });
        return;
      }
      
      let params = { phone: this.userInfo.phone, code: this.OTPCode };
      let request = verifySMSOTP
      if(this.recoveryMode == this.recoveryModes.email) {
        params = { email: this.userInfo.email, code: this.OTPCode }
        request = verifyEmailOTP;
      }


      request(params).then(res => {
        //console.log("res------send------", res)

        // Check response
        if (res.success && res.data) {
          this.stage = this.stages.enterNewPin;
          Notify({ type: 'success', message: this.$t('otpVerified')});

        }else{
          Notify(res.errorMsg || this.$t('errorValidatingOTP'));
        }
      })
      .catch(err => {
        //console.log('=====errorerrorerrorerror=Try your luck>>>==========', err);
        clearInterval(this.intervalId);
        Notify({
          duration: 5000,
          message: this.$t('failedToVerifyOTP'),
          type: 'danger'
        });
      })
    }
  },
};
</script>

<style lang="less" scoped>
.text-primary {
  color: #990099;

}

#su-email{
  display: block;
  width: 100%;
  border: 1px solid #ccc !important;
  border-radius: 5px;
  padding: 16px;
}
.switch-su-type{
  color: #990099;
  cursor: pointer;
}

.title,
.title * {
  text-align: center;
}

.title h1 {
  // color: #141867;
  color: rgb(146, 38, 144);
  padding: 14px 0;
  font-size: 24px;
}

.selected {
  border: 2px solid #990099;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.text-secondary {
  color: rgb(91, 87, 87);
}

.text-bold {
  font-weight: bold;
}

.top-content {
  height: 225px;
  display: flex;
  justify-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}


.heading {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin: 10px 20px;
  display: flex;
  justify-items: center;
  color: #fff;
}

.slogan {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: 5px 10px;
  color: #fff;
}

.additional-info {
  // padding: 10px;
  font-size: smaller;
  padding-top: 10px;
}

a {
  font-size: 13px;
}

.fade-leave-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.pr-10{
  padding-right: 10px;
}
.pt-10{
  padding-top: 10px;
}
.text-primary{
  color: #990099 !important;
}
.d-flex{
  display: flex;
}
.w-100{
  width: 100%;
}
.text-underline{
  text-decoration: underline;
}
.text-dark{
  color: #222;
}
.text-fade{
  opacity: .5;
}
</style>