import { cortexBaseUrl, tzFetch, HOST, noSandBox} from "../../../utils/http";


//whoami
async function whoami() {
  return tzFetch(`${noSandBox}/ba021/v1/my/info-with-org-account`, {}, 'POST');
}
//logout
async function logout() {
  return tzFetch(`${noSandBox}/ba021/v1/logout`, {}, 'POST');
}

//send OTP
async function sendSMSOTP(phoneNumber = null) {
  return tzFetch(`${noSandBox}/ba021/v1/sms/otp`, {phone: phoneNumber}, 'POST');
}

//verify OTP
async function verifySMSOTP(phoneNumber = null, code=null) {
  return tzFetch(`${noSandBox}/ba021/v1/login`, {phone: phoneNumber, code:code}, 'POST');
}

async function loadPaymentMethods(flowId = null) {
  // return tzFetch(`${cortexBaseUrl}/list-pm-operators?flowId=${flowId}`, {}, 'GET');
  return tzFetch(`${cortexBaseUrl}/list-payment-methods?flowId=${flowId}`, {}, 'GET');
}

async function createTransaction(flowId, payerAccountId, mobileNumber, email = undefined, paymentProcessorReturnUrl = undefined) {
  return tzFetch(
    `${cortexBaseUrl}/create-transaction`,
    { flowId, payerAccountId, mobileNumber, email, paymentProcessorReturnUrl },
    'POST'
  );
}

async function updateTransaction( _id, payerAccountId, mobileNumber = undefined, email = undefined) {
  return tzFetch(
    `${cortexBaseUrl}/update-transaction`,
    { payerAccountId, _id, mobileNumber, email },
    'POST'
  );
}

async function getTransaction(txnId) {
  return tzFetch(
    `${cortexBaseUrl}/view-transaction?_id=${txnId}`, {}, 'GET'
  );
}

async function getPaymentRequest(prId) {
  return tzFetch(
    `${cortexBaseUrl}/view-flow?flowId=${prId}`, {}, 'GET'
  );
}

async function submitRequestForApproval(flowId = '') {
  return tzFetch(
    `${cortexBaseUrl}/submit-flow-for-approval`, {flowId}, 'POST'
  );
}

async function capturePayment(pId) {
  return tzFetch(
    `${cortexBaseUrl}/capture`, { _id: pId }, 'POST'
  );
}

async function capturePaymentBalance(pId, pin) {
  return tzFetch(
    `${cortexBaseUrl}/capture`, { _id: pId, paymentPin:pin }, 'POST'
  );
}

async function cancelPayment(pId, account) {
  return tzFetch(
    `${cortexBaseUrl}/capture`, { _id: pId, payerAccountId: account }, 'POST'
  );
}

async function getTransactionStatus(txnId) {
  return tzFetch(
    `${cortexBaseUrl}/poll-transaction?_id=${txnId}`, {}, 'GET'
  );
}
async function pollFlow (flowId) {
  return tzFetch(
    `${cortexBaseUrl}/poll-flow?flowId=${flowId}`, {}, 'GET'
  );
}

async function refreshTransactionStatus(_id) {
  return tzFetch(
    `${cortexBaseUrl}/refresh-inbound-payment-status`, {_id}, 'POST'
  );
}

async function getTransactionFromPartnerResult(transactionRef) {
  return tzFetch(
    `${cortexBaseUrl}/view-by-ext-txn-ref?inboundExtTxnId=${transactionRef}`, {}
  );
}

async function refreshFlow (flowId) {
  return tzFetch(
    `${cortexBaseUrl}/refresh-flow-status`, { flowId }, 'POST'
  );
}

export {
  refreshTransactionStatus,
  getTransactionFromPartnerResult,
  getTransactionStatus,
  loadPaymentMethods,
  createTransaction,
  updateTransaction,
  getPaymentRequest,
  getTransaction,
  capturePayment,
  capturePaymentBalance,
  cancelPayment,
  refreshFlow,
  pollFlow,
  sendSMSOTP,
  verifySMSOTP,
  submitRequestForApproval,
  whoami,
  logout
};