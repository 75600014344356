<template>
  <!-- Payment methods -->
  <div class="tz-payment__section_content__pm-types">
    <payment-method-type @click="selectType(type)" :key="type.type" v-for="(type, idx) in types" :logos="type.logos"
      :selected="type.selected" :class="{ last: idx == types.length - 1 }" />
  </div>
</template>

<script>
import { isMobile } from '../../utils';
import PaymentMethodType from './PaymentMethodType.vue';
import websdkStore from '../../websdk.store';
export default {
  components: {
    PaymentMethodType
  },
  props: {
    pmTypes: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      types: []
    }
  },

  mounted() {
    setTimeout(() => {
      // if (this.types && this.types.length > 0) {
        // this.selectType(this.types[0]);
      // }
    }, 500);
  },
  methods: {
    selectType(type) {
      websdkStore.selectedPMType = type.type;
      const selectedType = this.types.find(x => x.selected == true);

      if (selectedType) {
        // if (selectedType.type == type.type) return;

        selectedType.selected = false;
      }

      type.selected = true;

      this.$emit('type-selected', type);
    },
    mapTypes() {
      const mappedTypes = this.pmTypes.reduce((r, c) => {
        const i = { type: c.type, logos: c.logos.slice(0, 2), selected: false };

        r.push(i);
        return r;
      }, []);

      this.$nextTick(() => {
        this.types = mappedTypes;
        if (this.types && this.types.length > 0 && !isMobile()) {
          this.selectType(this.types[0]);
        }
      });
    }
  },
  watch: {
    pmTypes: {
      immediate: true,
      handler: function (newVal, oldVal) {
        //console.log('PM_TYPES_WATCH', newVal, oldVal);
        this.mapTypes();
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.tz-payment__section_content__pm-types {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding-right: 12px;
  border-right: 2px solid #E1E1E1;
}

@media (max-width: 568px) {
  .tz-payment__section_content__pm-types {
    border: none !important;
    padding: 0;
  }
}
</style>