<template>
  <div class="container">
    <div class="wrapper">

      <template v-if="transactionPending">
        <!-- <div class="loader mb-64"></div> -->
        <div class="message"> {{ $t('pleaseWaitWhileThingsGetReady') }}{{ dots }} </div>
        <div class="cancel-polling">
          <tz-button type="primary" color="#990099" style="padding-left: 30px; padding-right: 30px;" size="small" round plain v-if="showRefreshButton" @click="cancelPolling">{{ $t('refresh') }}</tz-button>
        </div>
      </template>
      <template v-else>
        <div class="icon">
          <svg width="150" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 71.577 71.577"
            style="enable-background:new 0 0 71.577 71.577; background-color: transparent; color: blueviolet;"
            xml:space="preserve">
            <g>
              <g>
                <polygon style="fill:#CCC;" points="46.525,57.523 25.052,57.523 17.894,64.681 53.683,64.681 		" />
                <polygon style="fill:#CCC;" points="42.295,6.896 42.27,6.939 41.461,16.065 49.546,29.304 39.5,15.554 41.568,7.763
			36.018,14.351 43.243,35.835 34.561,33.809 40.709,48.314 26.924,28.631 36.318,28.985 26.312,16.989 29.085,6.896 0,6.896
			0,53.422 71.577,53.422 71.577,6.896 		" />
              </g>
            </g>
          </svg>
        </div>
        <p class="message">{{ $t('somethingWentWrongWhileInitializing') }}<br />
          {{ $t('pleaseRetryInAFewMinutes') }}</p>

        <tz-button plain type="danger" :text="$t('retry')" @click="loadTransaction" />
      </template>
    </div>
  </div>
</template>

<script>
import transactionMixin from '../../mixins/transaction.mixin';
import websdkStore from "../../websdk.store";
import { refreshFlow,pollFlow } from '../../api';

const STATUS_PENDING = 1;
const STATUS_PAYMENT_IN_PROGRESS= 2;
const STATUS_COMPLETED = 3;
const STATUS_FAILED = -1;
const STATUS_CANCELLED = -2;

const FLUTTER_WAVE_CANCELLED = 'cancelled'

export default {
  data() {
    return {
      dots: '.',
      info: {},
      transactionPending: true,
      flowId: null,
      pollRetries:500,
      pollInterval: 5000, // seconds
      intervalId: null,
      transactionId: null,
      success: false
    };
  },
  mixins: [transactionMixin],
  mounted() {
    let counter = 1
    setInterval(() =>{
      if (counter> 3) counter = 0;
      this.dots = '.'.repeat(counter);
      counter++
    },300)
    this.type = null;
    this.isRef = true;

    this.flowId = this.$route.params.rid // this.$route.query?.tx_ref;
    this.pollTransactionId = this.transactionRef;
    // pollingMethod
    this.checkPaymentStatus();
    const query = this.$route.query;
    if(query.status && query.status == FLUTTER_WAVE_CANCELLED){
      setTimeout(this.cancelPolling, 3000);
    }
  },
  methods: {
    cancelPolling(){
      this.intervalId = clearInterval(this.intervalId);
      this.$router.replace(`/?rid=${this.flowId}`)
    },
   async checkPaymentStatus() {
      //console.log("======||||||||||||||||||||this.$route||||||||||||||||-----------",this.$route.params);
      const refreshResp =  await refreshFlow(this.flowId);
      //console.log("refreshResp===========",refreshResp);

      this.pollPayment();
    },
    pollPayment () {
      setTimeout(e=>{this.showRefreshButton = true}, 15000);

      if (!this.intervalId) {
        this.intervalId = setInterval(() => {
          //Decrement retires.
          this.pollRetries--;

          pollFlow(this.flowId)
            .then(res => {
              //console.log("res------polling------",res)
              this.info = websdkStore;

              // Check response
              if (res.success && res.data) {
                //If request no more pending....
                if (res.data.status == STATUS_COMPLETED || res.data.status < 0) {
                  // Stop polling
                  clearInterval(this.intervalId);

                  // Globally store result.

                    websdkStore.paymentResult = res.data.transaction;

                    const isSuccess = res.data.status == STATUS_COMPLETED;

                    this.$router.replace({ name: isSuccess ? 'success':'failed' });
                } else if (this.pollRetries <= 0) {
                  // Stop polling.
                  clearInterval(this.intervalId);
                  // Navigate to result page.
                  // websdkStore.paymentResult = res.data;
                  // this.$router.replace({ name: 'failed' });
                  Notify({
                    duration: 5000,
                    message: this.$t('unableToProceedPleaseReload'),
                    type: 'warning'
                  });
                }
              }
            })
            .catch(err => {
              //console.log('=========error=======', err);
              clearInterval(this.intervalId);
              Notify({
                duration: 5000,
                message: this.$t('anErrorOccurredWhileProcessingTryRefresh'),
                type: 'danger'
              });
            })
        }, this.pollInterval);
      }
    }
  }
}
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: #d6cece46;
}

.wrapper {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message {
  color: #990099;
  text-align: center;
  font-style: italic;
  font-size: medium;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media (max-width: 568px) {
  .container{
    height: 100vh !important;
  }
}
</style>