<template>
  <div id="approval-page">
    <div id="container">
      <div class="main-div">
        <div class="user-info-container">
          <user-info />
        </div>
        <div class="text-center">
          <tz-icon name="lock" size="60" color="#f002"/>
        </div>
        <h3 class="title">{{ $t('approvalRequired') }}</h3>
        <p class="description">
          {{ $t('allTransactionsMustGoThroughApprovalProcessPleaseSubmitTheTransactionForApproval') }}
        </p>
        <div class="buttons d-flex">
          <div v-if="!flowAvailable" style="padding-top: 10px">
            <tz-button @click="submitForApproval()" color="#990099" round>{{ $t('submitForApproval') }}</tz-button>
          </div>
          <div style="padding-top: 10px" v-else>
            <tz-button @click="viewApprovalStatus()" color="#990099" plain round>{{ $t('viewApprovalStatus') }}</tz-button>
          </div>
          <div style="padding-top: 10px" class="">
            <tz-button @click="logout()" style="min-width: 120px;" color="#ab0e1d" plain round>{{ $t('logout') }}</tz-button>
          </div>
        </div>
      </div>
      <copyright style="font-size: 12px;" />
    </div>

  </div>
</template>
<script>
  import websdkStore from '../../websdk.store';
  import UserInfo from './UserInfo.vue';
  import Copyright from './Copyright.vue';
  import { submitRequestForApproval } from '../../api'
  import { Notify } from 'tzui-vue';
  var emitter = require('tiny-emitter/instance');


  export default {
    name: 'ApprovalPage',
    components: {
      UserInfo,
      Copyright,
    },
    computed: {
      getApprovalUrl(){
        return this.getBaseUrl + websdkStore?.paymentFlow?.approvalFlowId
      },
      getBaseUrl(){
        if(process.env.NODE_ENV !== 'production'){
          return 'https://staging-biz.tranzak.me/apps/workflow/approvals/progress/'
        }
        return 'https://biz.tranzak.me/apps/workflow/approvals/progress/'
      },
      flowAvailable(){
        return websdkStore?.paymentFlow?.approvalFlowId? true: false;
      }
    },
    data(){
      return {

      }
    },
    methods: {
      logout(){
        emitter.emit('log-user-out');
      },
      submitForApproval(){
        submitRequestForApproval(websdkStore?.paymentFlow?.flowId).then(res=>{
          if(res.success){
            location = this.getBaseUrl + res.data.approvalFlowId;
          }else{
            Notify(res.errorMsg || this.$t('failedToSubmitForApproval'));
          }
        }).catch(err=>{
          console.error(err);
        });
      },
      viewApprovalStatus(){
        location = this.getApprovalUrl;
      }
    }
  }
</script>
<style lang="scss">
  #approval-page {
    background: rgb(246, 245, 245);
    #container{
      display: flex;
      max-width: 600px;
      // background: orange;
      overflow: hidden;
      padding: 10px;
      height: 96vh;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      margin: auto;
    }
    .main-div{
      background: #fff;
      max-height: 600px;
      min-height: 350px;
      overflow-y: auto;
      height: auto;
      border-radius: 8px;
      padding: 15px;
    }
    .title{
      text-align: center;
      color: #990099;
      padding: 10px;
    }
    .description{
      padding: 15px;
      text-align: center;
      font-size: 14px;
    }
    .buttons{
      padding: 10px;
      justify-content: space-around;
    }
    .user-info-container{
      padding-bottom: 30px;
    }
  }
</style>