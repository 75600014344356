import { createApp } from 'vue'
import App from './App.vue'

// Global CSS styles
import '@/assets/css/style.css'
import '../node_modules/flag-icons/css/flag-icons.css';

import {
  ActionSheet,
  Form, Field, CellGroup,
  Image as TzImage,
  Popup, DropdownMenu,
  DropdownItem, Button, Rate,
  Collapse, CollapseItem,
  Col, Row, List, NavBar, Cell, Empty, Dialog, NumberKeyboard, PasswordInput, Loading, Icon, Toast, Grid, GridItem, Popover, Badge
} from 'tzui-vue';

import selectorComponents from 'vue3-country-selector';

import { createRouter, createWebHistory } from 'vue-router'

import routes from '@/routes/index';

// change language
import { Locale } from 'tzui-vue';
import enUS from 'tzui-vue/es/locale/lang/en-US';
import { clearLocal, getLocal, hideLoader, setLocal, showLoader } from './utils';

showLoader();

var queryParams = new URLSearchParams(location.search);
//console.log("Query params before------------------", queryParams)

let token = queryParams.get('token');
const lang  = queryParams.get('lang') || getLocal('lang') || 'en';
const isTopUp = queryParams.get('isTopUp');
let rid = queryParams.get('rid');
const oldToken = getLocal('tzpay-token');
const tmpToken = getLocal('tzpay-tmp-token');

import TzWebLogin from 'tz-web-login';
import 'tz-web-login/dist/style.css';

if (token && token != 'null') {
  setLocal('tzpay-token', token)
}else if(tmpToken){
  setLocal('tzpay-token', tmpToken)
}
else{
  if(!oldToken || oldToken == 'null'){
    clearLocal('tzpay-token');
    clearLocal('tzpay-user');
  }
}
clearLocal('tzpay-tmp-token');

if(!rid){
  getLocal('tzpay-rid');
  if(rid){
    router.replace('/?rid=rid');
  }
}else{
  setLocal('tzpay-rid',rid)
}


if (lang) {
  if (lang.toLowerCase().indexOf('fr') > -1) {
    setLocal('lang', 'fr');
  } else {
    setLocal('lang', 'en');
  }
}

Locale.use('en-US', enUS);

const myApp = createApp(App);

myApp.use(NumberKeyboard);
myApp.use(PasswordInput);
myApp.use(TzWebLogin);
myApp.use(ActionSheet);
myApp.use(Form);
myApp.use(Loading);
myApp.use(Field);
myApp.use(CellGroup);
myApp.use(Popup);
myApp.use(DropdownMenu);
myApp.use(DropdownItem);
myApp.use(Button);
myApp.use(Col);
myApp.use(Row);
myApp.use(List);
myApp.use(NavBar);
myApp.use(Cell);
myApp.use(Empty);
myApp.use(Dialog);
myApp.use(Icon);
myApp.use(Toast);
myApp.use(Grid);
myApp.use(TzImage);
myApp.use(GridItem);
myApp.use(Popover);
myApp.use(Badge);
myApp.use(Rate);
myApp.use(Collapse);
myApp.use(CollapseItem);
myApp.use(selectorComponents);
myApp.use(DropdownMenu);
myApp.use(DropdownItem);

import websdkStore from './views/Components/websdk/websdk.store';


myApp.config.globalProperties.$t = e=> {
  if(!websdkStore.trans[websdkStore.lang][e]){

    console.warn(websdkStore.lang +' translation not found for: '+e)
  }
  return websdkStore.trans[websdkStore.lang][e] || websdkStore.trans.en[e] || e
}

// else{
//   myApp.config.globalProperties.$t =  e=> {
//     if(!websdkStore.trans.en[e]){
//       console.warn('English translation not found for: '+e)
//     }
//     return websdkStore.trans.en[e] || e
//   }
// }


const router = createRouter({
  history: createWebHistory('/'),
  routes, // short for `routes: routes`
})


router.beforeEach((to, from, next) => {
  showLoader();

  if (to.meta?.canEnter) return next();

  if ((!from || !from.name) && to.name != 'entry') return next({ name: 'entry', query: from.query, params: from.params });
  else return next();
});

router.afterEach((to, from) => {
  hideLoader();
})

// add router
myApp.use(router);

myApp.mount('#app')

window.onbeforeunload = function() {
  if(location.href.includes('localhost')){
    return
  }
  localStorage.removeItem('tzpay-token');
};
