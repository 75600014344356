import { Notify } from 'tzui-vue';
import { getTransaction, getTransactionFromPartnerResult, getTransactionStatus, refreshTransactionStatus } from "../api";
import websdkStore from "../websdk.store";

const MOBILE_STATUS_FAILED = -40;
const MOBILE_STATUS_SUCCESS = 40;
const MOBILE_STATUS_SUCCESS2 = 31;
const MOBILE_STATUS_SUCCESS3 = 32;

const CARD_STATUS_PENDING_REDIRECT = 21;

const transactionMixin = {
  data() {
    return {
      showRefreshButton: false,
      type: null,
      isRef: false,
      pollDelay: 5000,
      pollRetries: 36,
      intervalId: null,
      pollTransactionId: null,
    };
  },
  methods: {
    resetTimer(){
      this.pollRetries = 36; // 
    },
    refreshPayment(){
      refreshTransactionStatus(this.pollTransactionId).then(res => {
        if (res.success && res.data) {
          
        }
      }).catch(err => {
        console.error(err);
      })
      clearInterval(this.intervalId);
      this.resetTimer();
      this.showRefreshButton = false;
      this.setPolling();
    },
    setPolling(){
      this.resetTimer();
      setTimeout(e=>{this.showRefreshButton = true}, 30000);
      const pollingMethod = this.isRef ? getTransactionFromPartnerResult : getTransactionStatus;
      this.intervalId = setInterval(() => {
        //Decrement retires.
        this.pollRetries--;

        pollingMethod(this.pollTransactionId)
          .then(res => {
            // Check response
            if (res.success && res.data) {
              // Evaluate response status.
              const status = this.handlePayment(res.data);
              //If request no more pending....
              if (status != 'pending') {
                // Stop polling
                clearInterval(this.intervalId);
                this.getTransactionAndContinue(res.data.transactionId ?? websdkStore.paymentTransaction._id, status);
                // If retries exceeded...
              } else if (this.pollRetries <= 0) {
                // Stop polling.
                clearInterval(this.intervalId);
                this.showRefreshButton = true;
                // Navigate to result page.
                // websdkStore.paymentResult = res.data;
                // this.$router.replace({ name: 'failed' });
                Notify({
                  duration: 10000,
                  message: this.$t('clickTheRefreshPaymentButtonToGetCurrentTransactionStatus'),
                  type: 'warning'
                });
              }
            }
          })
          .catch(err => {
            console.log(err);
            clearInterval(this.intervalId);
            this.showRefreshButton = true;
            Notify({
              duration: 5000,
              message: this.$t('anErrorOccurredWhileProcessingTryRefresh'),
              type: 'danger'
            });
          })
      }, this.pollDelay);
    },
    pollPayment () {
      this.showRefreshButton = false;
      if(!this.showRefreshButton){
        setTimeout(e=>{
          this.showRefreshButton = true
          this.refreshPayment();
        }, 22000);
      }
      // Get correct polling method.
      if (!this.intervalId) {
        this.setPolling( );
      }
    },
    handlePayment(payment) {
      switch (this.type) {
        case 'card':
          if (payment.status < 0) return 'failed';
          else if (payment.status == MOBILE_STATUS_SUCCESS || payment.status == MOBILE_STATUS_SUCCESS2 || payment.status == MOBILE_STATUS_SUCCESS3  ) return 'success';
          else if (payment.status == CARD_STATUS_PENDING_REDIRECT) return 'redirect';
          else return 'pending';
          
        case 'mobile':
        default:
          if (payment.status < 0) return 'failed';
          else if (payment.status == MOBILE_STATUS_SUCCESS || payment.status == MOBILE_STATUS_SUCCESS2 || payment.status == MOBILE_STATUS_SUCCESS3  ) return 'success';
          else if (payment.status == CARD_STATUS_PENDING_REDIRECT) return 'redirect';
          else return 'pending';
      }
    },

    getTransactionAndContinue(txnId, status, navigateDirectly = false) {
      getTransaction(txnId)
        .then((tnxResult) => {
          // Globally store result.
          websdkStore.paymentResult = tnxResult.data;

          //console.log("================tnxResult.data======<<>>>:>>>>>>>>====", JSON.stringify(tnxResult.data, undefined, 2));


          // Redirect if needed
          if (status == 'redirect') {
            return window.location.replace(tnxResult.data.inboundExternalPayment?.partnerPaymentAuthorizationUrl);
          }

          // Or navigate...
          if (navigateDirectly) {
            return this.$router.replace({ name: status });
          }

          setTimeout(() => {
            this.$router.replace({ name: status });
          }, 1000);

        })
        .catch(tnxError => {
          console.log(tnxError);

          Notify({
            message: this.$t('anErrorOccurredWhileProcessingTryRefresh'),
            type: 'danger',
            duration: 5000
          })
        });
    }
  }
};

export default transactionMixin;
