<template>
  <div class="container">
    <div class="wrapper">
      <!-- <div class="header">
        <p class="title">Payment in Progress</p>
      </div> -->
      <div class="body">
        <!-- <div class="loader mb-64"></div> -->
        <p class="pending-message">{{ trans.paymentInProgress }}</p>
        <p class="pending-message loading">
          {{ trans.waitingPaymentAuthorization }}
          <!-- <span class="dot-flashing"></span> -->
        </p>
        <p v-if="isMobileWalletPayment" class="processing-message my-30">{{ trans.processingStep }}: <span
            class="operator">{{ operator }}:</span> <span style="font-weight: bolder">{{ userPhone }}.</span> {{ trans.pleaseWait }} </p>
        <p v-else class="processing-message my-30">{{ trans.contactingPaymentServiceProviderPleaseWait }}</p>
        <a :href="`tel:${operatorCode}`" v-if="showDial" class="dial-message">{{ trans.dial }} {{ operatorCode }} {{ trans.toConfirmPayment }}</a>
        <p v-if="showRefreshButton" class="refresh-message">
          <tz-button block @click="refreshPayment" color="#990099">{{ trans.refreshPayment }}</tz-button>
        </p>
        <p v-if="showCancel" @click="cancelPayment" class="cancel-message">{{ trans.changePaymentMethod }}</p>
      </div>
      <copyright></copyright>
    </div>
  </div>
</template>
 
<script>
import { capturePayment, capturePaymentBalance, cancelPayment } from '../../api';
import transactionMixin from '../../mixins/transaction.mixin';
import { PAYMENT_METHOD_TYPES } from '../../utils';
import websdkStore from '../../websdk.store';
import Copyright from './Copyright.vue';
import { Notify } from 'tzui-vue';

export default {
  components: {
    Copyright,
    Notify
  },
  data() {
    return {
      showDial: false,
      showRefresh: false,
      showCancel: false,
      intervalId: null,
      trans: websdkStore.trans.en,
      pollRetries: 10,
      userPhone: "",
      showDials: true,
      type: null
    }
  },
  mixins: [transactionMixin],

  computed: {
    isMobileWalletPayment() {
      //console.log("websdkStore",websdkStore);
      // if(websdkStore.paymentMethod){
      //   // if(websdkStore.isMobile == true){
      //   //   return true
      //   // } 
      //   return websdkStore.paymentMethod.type == PAYMENT_METHOD_TYPES.MOBILE_WALLET.type;
      // }
      if(websdkStore.selectedPMType && websdkStore.selectedPMType == PAYMENT_METHOD_TYPES.MOBILE_WALLET.type) return true;
      return false;
    },
    operator() {
      return websdkStore.paymentMethod?.serviceName;
      return websdkStore.paymentTransaction?.payerAccount?.serviceProvider;
    },
    operatorCode() {
      return websdkStore?.paymentTransaction?.payerAccount?.ussdDialCode
    }
  },
  mounted() {
    this.trans = websdkStore.trans[websdkStore.lang];
    this.showDials = this.$route.meta?.showDials || false;
    this.type = websdkStore.pipType || 'mobile';
    this.userPhone = websdkStore.userPhone || '';
    this.makePayment();
    

  },
  methods: {
    cancelPayment() {
      clearInterval(this.intervalId);
      cancelPayment(websdkStore.paymentTransaction._id)
        .then((res) => {
          if (res && res.data) {
            this.$router.replace({ name: "select-mode" });
          }
        });
    },
    makePayment() {
      //console.log("websdkStore on payment completion",websdkStore);
      if(websdkStore.isBalance){
        capturePaymentBalance(websdkStore.paymentTransaction._id, websdkStore.pin)
        .then((res) => {
          if (res.success && res.data) {
            console.log(res);
            this.getTransactionAndContinue(res.data.transactionId ?? websdkStore.paymentTransaction._id, 'success');
          }else{
            this.showCancel = true;
            this.showRefresh = false;
            Notify({
              type: 'warning',
              duration: 5000,
              message: res.errorMsg,
            });
            setTimeout(() => {
              this.$router.replace({ name: "confirm-tnx" });
            }, 3000);
            }
        })
        .catch(err => {
          this.showCancel = true;
          this.showRefresh = false;
          console.log(err);
          Notify({
            type: 'danger',
            duration: 5000,
            message: $t('anErrorOccurredWhileProcessingPleaseRetry'),
          });
          setTimeout(() => {
            this.$router.replace({ name: "confirm-tnx" });
          }, 3000);
        });
      }else{
        capturePayment(websdkStore.paymentTransaction._id)
        .then((res) => {
          if (res.success && res.data) {
            websdkStore.paymentTransaction = res.data;
            setTimeout(() => {
              this.showDial = this.showDials && !!websdkStore.userInfo.operator?.ussdMobileMoneyDialCode && true;
              this.showDial = this.showDials && !!websdkStore.paymentTransaction.payerAccount?.ussdDialCode&& true;
              setTimeout(() => {
                this.showRefresh = true;
                setTimeout(() => {
                  this.showCancel = this.showDials && true;
                }, 2 * 1000);
              }, 4 * 1000);
            }, 10 * 1000);

            this.pollTransactionId = websdkStore.paymentTransaction._id;

            this.pollPayment();
          }else {
            this.showCancel = true;
            Notify({
              type: 'danger',
              duration: 10000,
              message: res.errorMsg,
            });
          }
        })
        .catch(err => {
          this.showCancel = true;
          this.showRefresh = false;
          setTimeout(() => {
            this.$router.replace({ name: "confirm-tnx" });
          }, 3000);
        });

      }
     
    },
  }
}
</script>

<style lang="scss" scoped>
$violet: #9F0095;

.container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: #eeeeee;
  overflow: hidden;
}

.wrapper {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  overflow: hidden;
  // box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 568px) {
  .container{
    height: 100vh !important;
  }
  .wrapper {
    height: 100%;
    transform: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
  }

  .header {
    .title {
      font-size: 1em;
    }
  }

  .body {
    // height: calc(100% - 120px);
    justify-content: start !important;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: $violet;
  height: 60px;

  .back-icon {
    height: 40px;
    width: 40px;
    float: left;
    border: 0;
  }

  .title {
    font-size: 1.3em;
    text-align: center;
    padding: 0;
    margin: auto;
    font-weight: 400;
    color: white;
    align-self: baseline;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  padding: 80px 12px 12px 20px;
  width: 100%;
  overflow-y: scroll;

  .pending-message {
    margin: 2px 0;
    font-size: .85em;
    font-weight: 700;

    &.loading:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 900ms infinite;
      animation: ellipsis steps(4, end) 900ms infinite;
      content: "\2026";
      /* ascii code for the ellipsis character */
      width: 0px;
    }
  }

  .processing-message {
    font-weight: lighter;
    text-align: center;

    .operator {
      padding-bottom: 1px;
      border-bottom: 1px solid rgb(180, 180, 180);
    }
  }

  .dial-message,
  .refresh-message,
  .cancel-message {
    display: block;
    font-weight: 300;
    color: $violet;
    text-decoration: underline 1px;
    cursor: pointer;
    animation: fadeIn 100ms;
    // margin: 20px 0;

    &:hover {
      font-weight: 500;
    }
  }

  // .refresh-message {
  //   margin-top: 3em;
  // }

  // .cancel-message {
  //   margin-top: 5em;
  // }
}


.tz-payment__section_copyright {
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  text-align: center;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
</style>