import Copyright from '../Components/shared/Copyright.vue';
import MwUserInfoForm from '../Components/web/MwUserInfoForm.vue';
import CardUserInfoForm from '../Components/web/CardUserInfoForm.vue';
import PaymentMethodList from '../Components/web/PaymentMethodList.vue';
import PaymentMethodTypeList from '../Components/web/PaymentMethodTypeList.vue';
import { Dialog } from 'tzui-vue';

import { loadPaymentMethods } from "../api";
import {
  CMR_COUNTRY_CODE,
  CMR_TEL_CODE,
  CURRENCY_USD,
  CURRENCY_XAF,
  PAYMENT_METHOD_TYPES,
  PAYMENT_METHOD_TYPES_ARRAY,
  REGEXP_MTN_CM,
  REGEXP_ORANGE_CM
} from '../utils';
import websdkStore from '../websdk.store';
import { getLocal } from '../../../../utils';
var emitter = require("tiny-emitter/instance");

const pmSelectMixin = {
  components: {
    PaymentMethodTypeList,
    PaymentMethodList,
    CardUserInfoForm,
    MwUserInfoForm,
    Copyright,
  },

  props: {
    redirectUrl: {
      type: String,
      // required: true,
      default: ''
    },
    allPaymentMethods:{
      type: Array,
      default: [],
    }
  },

  data() {
    return {
      defaultPMSelected: 0,
      paymentMethodsRawResult: [],
      typesEnum: PAYMENT_METHOD_TYPES,
      pmTypes: [],
      selectedType: null,
      paymentMethods: {},
      visaMasterAccount: "",
      isCurrencyPmSelected: false,
      cardPaymentMethods: {},
    };
  },



  created() {
    this.initPaymentMethods();
    emitter.on('user-logged-in', this.userLoggedIn);
  },

  mounted() {
    // setTimeout(() => {
    //   const container = document.querySelector('.tz-payment__section_container');

    //   container.style.minHeight = container.style.height;

    //   console.log(container.style.height, container.style);
    // }, 5000);
  },

  computed: {
    paymentMethodTypeList() {
      //console.log("pm types:::>>>>>>>>>>>>>>", this.pmTypes)
      return this.pmTypes.map(x => x).sort((a, b) => a.rank - b.rank);
    },

    // hasLinked() {
    //   const a = this.paymentMethods[this.typesEnum.MOBILE_WALLET.type];

    //   return a && !!Object.entries(a).length;
    // },
     hasMobileWallet() {
      const a = this.paymentMethods[this.typesEnum.MOBILE_WALLET.type];

      return a && !!Object.entries(a).length;
    },
    hasDebitCard() {
      const a = this.paymentMethods[this.typesEnum.DEBIT_CARD.type];

      return a && !!Object.entries(a).length;
    },
    hasVisaMaster() {
      const a = this.paymentMethods[this.typesEnum.VISA_MASTERCARD.type];
      return a && !!Object.entries(a).length;
    },
    hasPayaPal() {
      const a = this.paymentMethods[this.typesEnum.PAY_PAL.type];
      return !!a;
    },
  },

  methods: {
    userLoggedIn(e){
      this.initPaymentMethods();
    },
    pmTypeSelected(type) {
      this.defaultPMSelected ++;
      this.selectedType = type;
      const oldPm = this.paymentMethodsRawResult.find(x => x.accountId == websdkStore.userInfo.operator?.accountId);
      if (oldPm) {
        oldPm.selected = false;
      }

      websdkStore.isCurrencyPmSelected = [
        PAYMENT_METHOD_TYPES.PAY_PAL.type,
        PAYMENT_METHOD_TYPES.VISA_MASTERCARD.type,
      ].includes(type.type);

      websdkStore.userInfo.operator = {};
      websdkStore.paymentMethodType = type;

      if(this.goToStep && this.steps.KEY_INFOS && this.defaultPMSelected>0){
        this.goToStep(this.steps.KEY_INFOS);
      }
    },
    setOtherCurrency(source) {
      if (!source) return;

      if (source[CURRENCY_USD]) {
        source.OTHER = source[CURRENCY_USD];
      } else if (source[CURRENCY_XAF]) {
        source.OTHER = source[CURRENCY_XAF];
      }
    },
    async initPaymentMethods() {
      try {
        const pmResult = await loadPaymentMethods(websdkStore.paymentFlow.flowId);
        
        if (pmResult && pmResult.success) {
          websdkStore.allPaymentMethods = pmResult.data;
          const finalPmResult = pmResult.data.map(pm => { return {...pm, 'type': pm.type ? pm.type.toString() : null }});
          //console.log("finalPmResult",finalPmResult);
          this.paymentMethodsRawResult = finalPmResult;
          //
          // Map entire payment methods.
          //
          let hasLinkedPM = false;

          const paymentMethods = finalPmResult.reduce((r, c) => {
            c.logo = c.avatarUrl;

            if (!r[c.type]) {
              r[c.type] = {};
            }

            const targetTypeObject = r[c.type];
            const LINKED_PAYMENT_METHOD_TYPE = 6;


            switch (c.type) {
              case PAYMENT_METHOD_TYPES.MOBILE_WALLET.type:
                if(c.accountType != LINKED_PAYMENT_METHOD_TYPE){
                  if (!targetTypeObject[c.countryCode]) {
                    targetTypeObject[c.countryCode] = [c];
                  } else {
                    targetTypeObject[c.countryCode].push(c);
                  }

                  if (c.countryCode == CMR_COUNTRY_CODE) {
                    c.code = CMR_TEL_CODE;
                    if (c.shortName == "Orange Money") {
                      c.validationMask = REGEXP_ORANGE_CM;
                    } else if (c.shortName == "MTN Momo") {
                      c.validationMask = REGEXP_MTN_CM;
                    }
                  }
                }else{
                  hasLinkedPM = true;
                }

                break;

              case PAYMENT_METHOD_TYPES.DEBIT_CARD.type:
                if (!targetTypeObject[c.countryCode]) {
                  targetTypeObject[c.countryCode] = [c];
                } else {
                  targetTypeObject[c.countryCode].push(c);
                }

                break;

              case PAYMENT_METHOD_TYPES.PAY_PAL.type:
              case PAYMENT_METHOD_TYPES.VISA_MASTERCARD.type:
                if (!targetTypeObject[c.currencyCode]) {
                  targetTypeObject[c.currencyCode] = c;
                } else if (c.countryCode == "CM" && targetTypeObject[c.currencyCode].countryCode != "CM") {
                  targetTypeObject[c.currencyCode] = c;
                }

                break;

              default:
                break;
            }


            return r;
          }, {});

          if(hasLinkedPM){
            paymentMethods[null] = {};
          }
          //console.log("paymentMethods",paymentMethods);

          this.setOtherCurrency(paymentMethods[PAYMENT_METHOD_TYPES.VISA_MASTERCARD.type]);
          this.setOtherCurrency(paymentMethods[PAYMENT_METHOD_TYPES.VISA_MASTERCARD.type]);

          const givenPmTypes = Object.keys(paymentMethods);

          this.paymentMethods = paymentMethods;
          var token = getLocal("tzpay-token") || null;
          if (token != null) {
            //console.log("Token---------------------");
          } else {
            //console.log("No token--------------------");
            PAYMENT_METHOD_TYPES_ARRAY.splice(3, 1);
          }
          this.pmTypes = PAYMENT_METHOD_TYPES_ARRAY.filter(x => givenPmTypes.includes(x.type));
          // this.$nextTick(() => {
          // });
        }
      } catch (error) {
        Dialog.confirm({
          // title: this.$t('alert'),
          message: this.$t('failedToLoadProperlyTryAgain'),
          cancelButtonText: this.$t('cancel'),
          confirmButtonText: this.$t('tryAgain'),
          confirmButtonColor: '#990099',
          confirm
        }).then(() => {
            // on confirm
            this.initPaymentMethods();
          })
          .catch(() => {
            // on cancel
          });
        console.error(error);
      }
    }
  },
};

export default pmSelectMixin;
