import websdkStore from "../websdk.store";

const userInfoMixin = {
  data() {
    return {
      country: null
    }
  },
  methods: {
    addCountryCodeToUserPhone() {
      const dial = this.country?.dial ?? this.country?.dial_code;

      if(websdkStore.userInfo.phone && websdkStore.userInfo.phone.includes('+')){
        websdkStore.userInfo.phone = `${websdkStore.userInfo.phone || ''}`;
      }
      else if (dial && !`${websdkStore.userInfo.phone}`.startsWith(dial)) {  
        websdkStore.userInfo.phone = `${dial}${websdkStore.userInfo.phone || ''}`;
      }
    },
    removeCountryCodeToUserPhone() {
      const dial = this.country?.dial ?? this.country?.dial_code;
      if (dial && `${websdkStore.userInfo.phone}`.startsWith(dial)) {
        websdkStore.userInfo.phone = `${websdkStore.userInfo.phone}`.substring(dial.length);
      }
    }
  },
  beforeUnmount() {
    this.removeCountryCodeToUserPhone();
  }
}

export default userInfoMixin;
