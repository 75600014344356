<template>
  <div>
    <div id="user-info-container" v-if="renderContent">
      <div @click="showDropDown()" class="text-right auth-text" v-if="tzpayToken != null && tzpayToken != 'null' && tzpayToken != '' && currentUser && currentUser.userId" >
        <div class="d-flex" style="justify-content: end; align-items: center">
          <div>
            <div class="text-center pr">
              <div  v-if="currentUser.avatar" :style="`padding: 0px; height: 35px; width: 35px; border-radius: 50%; background-position: center; background-size: cover; background-image: url(${currentUser.avatar})`">
                <!-- <img height="35" style="border-radius: 50%;" :src="currentUser.avatar" alt=""> -->
              </div>
              <tz-icon v-else name="contact" color="#fff" size="25" style="background: #99009966; padding: 5px; border-radius: 50%"/>
            </div>
          </div>
          <div class="w-100 tz-ellipsis" style="text-align: left; width: 250px;">
            <div>
              <span>{{ $t('payingAs') }}  {{  currentUser.fullName || currentUser.displayName || (hasPhone && currentUser.phone) || currentUser.email || currentUser.name }}</span>
            </div>
            <div v-if="hasOrg">
              <span>({{ orgUser.displayName }})</span>
            </div>
          </div>
          <div>
            <div class="dropdown text-right" >
              <p class="dropbtn">
                <tz-icon v-if="toggleUp" name="arrow-up" size="20" color="black" />
                <tz-icon v-else name="arrow-down" size="20" color="black" />
                <!-- <i class="arrow down"></i> -->
              </p>
            </div>
          </div>
        </div>
        <div style="position: relative">
          <div class="dropdown-content text-right"  id="myDropdown" tabindex="0"  @click="logout()">
            <a class="link" href="#" tabindex="0" style="padding-left: 5px; padding-right: 5px;"> <tz-icon name="close-o" /> {{ $t('logout') }} </a>
          </div>
        </div>
      </div>
      <div class="text-right" v-if="showRefreshButton">
        <tz-button plain round size="small" @click="getUserInfo" color="#990099"> {{ $t('refreshPayersInfo') }} </tz-button>
      </div>
    </div>
    <div v-else>
      <div style="display: flex; align-items: center;">
        <div>
          <img style="" height="35" :src="customization.logoUrl" alt="">
        </div>
        <div style="font-size: 18px; padding-left: 4px; font-weight: bolder;" class="long-tz-ellipsis">{{ customization.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Notify } from 'tzui-vue';
import { clearLocal, getLocal } from '../../../../../utils';
import { whoami, logout } from '../../api';
import websdkStore from '../../websdk.store';
var emitter = require('tiny-emitter/instance');

  export default {
    name: 'UserInfo',
    data(){
      return{
        orgUser: {},
        toggleUp: false,
        hasOrg: false,
        hasPhone: false,
        showRefreshButton: null,
        tzpayToken: null,
        currentUser:{},
      }
    },
    mounted(){
      emitter.on('log-user-out', this.logout);
      const exitingToken = getLocal("tzpay-token");

      if (exitingToken != null ) {
        this.tzpayToken = exitingToken;
      } else {
        this.tzpayToken = null;
      }
      if(this.tzpayToken){
        this.getUserInfo();
      }

    },
    computed:{
      renderContent(){
        if(websdkStore && websdkStore.paymentInfo && !websdkStore.paymentInfo.customization){
          return true
        }
        return false
      },
      customization(){
        return websdkStore.paymentInfo?.customization || {}
      }
    },
    methods: {
      getUserInfo(){
        whoami().then(res => {
          if(res.success){
            this.currentUser = res.data.userInfo;
            this.currentUser.phone? this.hasPhone = true: false
            this.showRefreshButton = false;
            this.orgUser = res.data.orgAccount;
            if(this.orgUser && this.orgUser.orgId) this.hasOrg = true;
            websdkStore.userInfo = JSON.parse(JSON.stringify(res.data.userInfo));
            return;
          }else if(res && (res.errorCode == 40001 || res.errorCode == 40002)){
            clearLocal("tzpay-token");
            clearLocal("tzpay-user");
            this.$router.go();
          }
          // this.showRefreshButton = true;
        }).catch(err => {
          //console.log('=========error=======', err);
          if(this.tzpayToken){
            this.showRefreshButton = true;
            Notify({
              duration: 5000,
              message: this.$t('failedToGetPayersInfo'),
              type: 'warning'
            });
          }
        })
      },
      logout(){
        //console.log('logout');
        logout().then((res) => {
          if(res.success){
            clearLocal("tzpay-token");
            clearLocal("tzpay-user");
            this.tzpayToken = null;
            let flowId = this.fid;
            if(flowId){
              flowId = '?rid='+flowId;
            }else{
              flowId = '';
            }
            emitter.emit('user-logged-out');
            websdkStore.userInfo = {email: null, phone: null, operator: null };
            this.$router.go();
          }
        }).catch(err => { 
          console.log(err);
          Notify({
            duration: 5000,
            message: this.$t('failedToLogoutPleaseTryAgain'),
            type: 'warning'
          });
        });
      },
      showDropDown(e) {
        try{
          this.toggleUp = !this.toggleUp;
          const element = document.getElementById("myDropdown");
          element.classList.toggle("show");
          if(this.toggleUp){
            // element.focus()
          }
        }catch(e){
          console.log(e);
        }
      },
    }
  }
</script>
<style lang="scss">
  #user-info-container{
      /* Dropdown Button */
    padding-bottom: 5px;
    user-select: none;
    .show {
      display: block !important;
    }


    .arrow {
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      margin-top: 5px;
      transform: rotate(45deg);

    }

    .link {
      float: left;
      font-size: 12px;
      color: white;
      text-align: center;
      padding: 5px 5px;
      text-decoration: none;
    }

    .dropdown {
      float: left;
      overflow: hidden;
    }

    .dropdown .dropbtn {
      cursor: pointer;
      font-size: 8px;
      border: none;
      outline: none;
      color: white;
      padding: 0px 2px;
      background-color: inherit;
      font-family: inherit;
      margin: 0;
    }

    .navbar a:hover, .dropdown:hover .dropbtn, .dropbtn:focus {
      color: rgb(146, 38, 144);;
    }

    .dropdown-content {
      right: 0;
      margin-top: 5px;
      margin-right: 0px;
      padding: 2px;
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
      z-index: 1;
    }

    .dropdown-content a {
      float: none;
      color: rgb(57, 54, 54);
      padding: 2px 4px;
      text-decoration: none;
      display: block;
      text-align: left;
    }

    .dropdown-content a:hover {
      background-color: #ddd;
    }
  }
</style>