<template>
  <div class="tz-payment__pm tz-selectable" :class="{ selected: selected }"
    :style="{ backgroundImage: `url(${logo})` }">
    <span v-show="selected" class="badge">
      <tz-icon style="margin-top: 2px;" name="success" color="white"></tz-icon>
    </span>
  </div>

</template>

<script scoped>
export default {
  props: {
    logo: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.tz-payment__pm {
  position: relative;
  width: 80px;
  border: 1px solid rgba(204, 204, 204, 0.322);
  height: 60px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.tz-payment__pm>.badge {
  width: 16px;
  height: 16px;
  font-size: 12px;
  top: -6px;
  right: -6px;
}
</style>