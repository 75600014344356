import { reactive } from "vue";
import { getLocal } from "../../../utils";
const en = require('../../../locale/en.json');
const fr = require('../../../locale/fr.json');


var queryParams = new URLSearchParams(location.search);
let tmpLang = queryParams.get('lang');
const localLang = getLocal('lang');
let lang = 'en';

if(tmpLang){
  lang  = tmpLang == 'fr'? 'fr': 'en';
}else if(localLang){
  lang  = localLang && localLang == 'fr'? 'fr': 'en';
}


export default reactive({
  paymentFlow: null,
  selectedPMType: null,
  paymentMethodType: null,
  paymentMethod: null,
  paymentInfo: null,
  isMobile: window.innerWidth <= 568 || window.innerHeight <= 400,
  paymentTransaction: null,
  paymentResult: null,
  redirect: null,
  pipType: 'mobile',
  isCurrencyPmSelected: false,
  userInfo: {
    email: null,
    phone: null,
    operator: null
  },
  paymentMethods: [],
  allPaymentMethods: [],
  canRedirect: true,
  lang: lang,
  trans: {
    en,
    fr,
  }
});
// deleteMany({createdAt: {$lte: "2022-07-01"}})