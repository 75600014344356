<template>
  <div style="height: 100vh; display: flex; justify-content: center; align-items: center;">
    <div class="payment-cancelled">
        <div class="user-info-details">
          <user-info />
        </div>
        <div class="payment-info-details text-primary">
          <h2 class="text-primary text-center">
            {{ formatCurrency(flow.amount, flow.currencyCode) }} <small>{{ flow.currencyCode }}</small>
          </h2>
          <div class="p-5 text-center text-danger">
            {{ $t('sorryThisTransactionCantBePaidAnyMore') }}
          </div>
          <div class="transaction-info-detail" v-for="(item, i) in displayItems" :key="i">
            <div class="text-primary">{{ $t(item.key) }}</div>
            <div class="description">
              <div v-if="item.image" style="padding-top: 5px;">
                <img :src="item.image" height="50" style="margin-right: 8px; border-radius: 6px;">
              </div>
              <div class="w-100" style="text-transform: capitalize;">
                {{ item.value }}
              </div>
            </div>
          </div>
          <div v-if="autoRedirect" class="p-5 text-center">
            {{ $t('youWillBeRedirectedIn') }} {{ autoRedirect }}...
          </div>
          <div class="text-center" style="padding: 30px 10px;" v-if="flow.returnUrl">
            <tz-button color="#990099" round size="small" style="padding-left: 50px; padding-right: 50px;" @click="goToReturnUrl">{{ $t('redirectNow') }}</tz-button>
            <div style="margin-top: 5px;">
              <span style="color: black">{{ $t('redirectingTo') }}: </span> <span class="cursor-pointer" @click="goToReturnUrl"> {{ flow.app && flow.app.name? flow.app.name: flow.beneRealName }} </span>
            </div>
          </div>
        </div>
        <div>
          <copyright />
        </div>
    </div>
  </div>
</template>
<script>
import websdkStore from '../websdk/websdk.store';
import UserInfo from '../websdk/Components/shared/UserInfo.vue';
import { formatCurrency } from '../../../utils';
import Copyright from '../websdk/Components/shared/Copyright.vue';

export default {
  components: {
    UserInfo,
    Copyright
  },
  data() {
    return {
      formatCurrency,
      autoRedirect: 0,
      intervalId: null,
    };
  },
  watch: {
    autoRedirect(data){
      if(data <=0){
        this.goToReturnUrl();
        clearInterval(this.intervalId);
      }
    }
  },
  mounted() {
    if(this.$route.params.showAutoRedirect && websdkStore && websdkStore.paymentFlow.returnUrl){
      this.autoRedirect = 10;
      this.intervalId = setInterval(e=>{
        this.autoRedirect-=1;
      }, 1000)
    }
  },
  computed: {
    flow(){
      return websdkStore.paymentFlow || {};
    },
    displayItems(){
      return [
        {key: 'flowId', value: this.flow.flowId, image: ''},
        {key: 'description', value: this.flow.description, image: ''},
        {key: 'merchant', value: (this.flow.customization? this.flow.customization?.title: '') || (this.flow.app && this.flow.app.name? this.flow.app.name: this.flow.beneRealName), image: this.flow.customization && this.flow.customization.logoUrl ? this.flow.customization.logoUrl: (this.flow.app && this.flow.app.avatarUrl ? this.flow.app.avatarUrl:  this.flow.beneAvatarUrl || '')},
        {key: 'status', value: this.flow.statusText || this.$t('cancelled'), image: ''},
      ]
    }
  },
  methods: {
    goToReturnUrl(){
      let fullUrl = new URL(this.flow.cancelUrl || this.flow.returnUrl);
      fullUrl = this.appendParamToQueryString(fullUrl, 'success', false);
      fullUrl = this.appendParamToQueryString(fullUrl, 'requestId', this.flow.flowId);
      if(this.flow.transactionId){
        fullUrl = this.appendParamToQueryString(fullUrl, 'transactionId', this.flow.transactionId);
      }
      window.location.replace(fullUrl);
    },
    appendParamToQueryString(url, name, value) {
      if (!url || !name) return url;

      return `${url}`.includes('?') ? `${url}&${name}=${value}` : `${url}?${name}=${value}`;
    },
  },
};
</script>

<style lang="less">
  html{
    background-color: #eee;
    height: 100vh;
  }
  .payment-cancelled{
    max-width: 600px;
    padding: 10px;
    width: 100%;
    margin: auto;
  }
  .payment-info-details{
    border-radius: 5px;
    background-color: #fff;
    padding: 15px;
  }
  .user-info-details{
    padding-bottom: 15px;
    text-align: right;
  }
  .transaction-info-detail{
    border-top:1px solid #ddd;
    padding: 15px 0px;
    .description{
      display: flex;
      align-items: center;
      font-weight: bold; color: #444;
    }
  }
</style>