<template>
  <div class="tz-web__container">
    <div class="tz-payment__section">
      <div class="tz-payment-section-body">
        <div class="tz-payment__section_container">
          <user-info />
          <div class="text-center" style="padding-top: 10px;">
            <TransactionAmount />
          </div>
          <div class="tz-payment__section_title">
            <h1 class="title">{{ trans.availablePaymentMethods }}</h1>
            <!-- <span v-if="currentStep == steps.CHOOSE_PM_TYPE" class="notice">Choose your best payment method</span> -->
          </div>
          <div class="tz-payment__section_content">
            <div v-show="currentStep == steps.CHOOSE_PM_TYPE">
              <payment-method-type-list @type-selected="pmTypeSelected" :pm-types="paymentMethodTypeList" />
              <tz-button style="margin-top: 12px; width: 100%;" type="primary" :loading="loading" color="#922690" :disabled="!selectedType"
                :text="$t('continue')" :loading-text="$t('processing')" @click="goToStep(steps.KEY_INFOS)">
              </tz-button>
            </div>
            <!-- Form -->
            <div v-show="currentStep == steps.KEY_INFOS" style="width: 100%;">
              <div class="tz-payment__section_form">
                <div class="tz-payment__section_form__title">
                  <h5>{{ trans.completeTheSteps }}</h5>
                </div>
                <div class="tz-payment__section_form__body">
  
                <!-- Link -->
                  <div v-if="selectedType?.type == typesEnum.LINK_PAYMENT_METHOD.type">
                    <div v-if="tranzakBalance && tzpayToken" @click="payWithBalance(tranzakBalance)"  style="display: flex; cursor: pointer;" class="card">
                      <div style="display: flex; justify-items: center; justify-content: center;"  class="">
                        <div style="display: flex; align-items:center;" class="">
                          <svg width="70" height="40" viewBox="0 0 234 225" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M103.5 7.09999C85.6 9.09999 65.8 17 51.1 28C32.2 42.3 18.8 62.2 12.2 86C9.8 94.7 9.5 97.1 9.5 113.5C9.5 129.9 9.8 132.3 12.2 141C20.8 171.7 40.9 196.2 68.3 209.5C118.3 233.6 177.7 216.8 206.7 170.3C228.9 134.6 228.2 89 204.7 53.8C182.6 20.6 143.6 2.59999 103.5 7.09999ZM125.8 66.3L126 89H138.1H150.2L146.6 82.6C144.6 79.1 143 76 143 75.7C143 75 152.1 69.7 152.5 70.2C153.1 70.9 168 97.9 168.6 99.4C169.2 100.8 167.1 101 147.6 101H126V106V111H147.5H169V116.5V122H147.5H126V141C126 161 126.5 163.9 130.6 166C133.7 167.7 141.7 166.7 147 164C149.2 162.9 151.4 162.1 151.8 162.4C152.3 162.7 153.7 165.1 155 167.9C157.7 173.8 157.4 174.4 150.2 178.3C139 184.4 125.7 185.7 117.3 181.5C106.6 176.2 104 168.1 104 140.8V122H92C85.4 122 80 122.2 80 122.4C80 122.7 81.6 125.6 83.5 128.9C85.4 132.1 87 135.2 87 135.7C87 137.1 78 141.4 77.1 140.4C76.1 139.2 61 112.2 61 111.5C61 111.2 70.7 111 82.5 111H104V106V101H82.5H61V95V89H82.5H104V68.1V47.1L109.8 46C112.9 45.3 117.1 44.4 119 44C125.8 42.4 125.5 41.2 125.8 66.3Z" fill="#990099"/>
                          </svg>
                        </div>
                      </div>
                      <div class="py-3">
                        <h4 class="my-2">{{tranzakBalance.shortName}}</h4>
                        <p class="my-2" v-if="tranzakBalance.balance >= 0 "> {{ new Intl.NumberFormat('en-EN').format(tranzakBalance.balance)}} {{tranzakBalance.currencyCode}}</p>
                      </div>
                    </div>
                    <div v-if="payoutBalance && tzpayToken" @click="payWithBalance(payoutBalance)"  style="display: flex; cursor: pointer;" class="card">
                      <div style="display: flex; justify-items: center; justify-content: center;"  class="">
                        <div style="display: flex; align-items:center;" class="">
                          <svg width="70" height="40" viewBox="0 0 234 225" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M103.5 7.09999C85.6 9.09999 65.8 17 51.1 28C32.2 42.3 18.8 62.2 12.2 86C9.8 94.7 9.5 97.1 9.5 113.5C9.5 129.9 9.8 132.3 12.2 141C20.8 171.7 40.9 196.2 68.3 209.5C118.3 233.6 177.7 216.8 206.7 170.3C228.9 134.6 228.2 89 204.7 53.8C182.6 20.6 143.6 2.59999 103.5 7.09999ZM125.8 66.3L126 89H138.1H150.2L146.6 82.6C144.6 79.1 143 76 143 75.7C143 75 152.1 69.7 152.5 70.2C153.1 70.9 168 97.9 168.6 99.4C169.2 100.8 167.1 101 147.6 101H126V106V111H147.5H169V116.5V122H147.5H126V141C126 161 126.5 163.9 130.6 166C133.7 167.7 141.7 166.7 147 164C149.2 162.9 151.4 162.1 151.8 162.4C152.3 162.7 153.7 165.1 155 167.9C157.7 173.8 157.4 174.4 150.2 178.3C139 184.4 125.7 185.7 117.3 181.5C106.6 176.2 104 168.1 104 140.8V122H92C85.4 122 80 122.2 80 122.4C80 122.7 81.6 125.6 83.5 128.9C85.4 132.1 87 135.2 87 135.7C87 137.1 78 141.4 77.1 140.4C76.1 139.2 61 112.2 61 111.5C61 111.2 70.7 111 82.5 111H104V106V101H82.5H61V95V89H82.5H104V68.1V47.1L109.8 46C112.9 45.3 117.1 44.4 119 44C125.8 42.4 125.5 41.2 125.8 66.3Z" fill="#990099"/>
                          </svg>
                        </div>
                      </div>
                      <div class="py-3">
                        <h4 class="my-2 text-capitalize">{{payoutBalance.shortName}}</h4>
                        <p v-if="payoutBalance.balance >= 0" class="my-1"> {{ new Intl.NumberFormat('en-EN').format(payoutBalance.balance)}} {{payoutBalance.currencyCode}}</p>
                      </div>
                    </div>
                  </div>
  
                  <div v-if="selectedType?.type == typesEnum.LINK_PAYMENT_METHOD.type">
                    <div v-for="linkedPM in linkedPMs" v-bind:key="linkedPM" @click="payWithLPM(linkedPM)" style="display: flex; align-items: center; cursor: pointer;" class="card">
                      <div class="">
                        <img class="tz-pm-logo" :src="linkedPM.avatarUrl" alt="">
                      </div>
                      <div class="py-3">
                        <!-- <h4 class="my-2 tz-ellipsis" style="">{{linkedPM.shortName}}</h4> -->
                        <div class="py-1">{{linkedPM.partnerAccountHolderId}}</div>
                      </div>
                    </div>
                    <tz-button style="width: 100%" type="danger" plain :text="$t('back')" @click="goToStep(steps.CHOOSE_PM_TYPE)"></tz-button>
                  </div>
  
                  <!-- Mobile wallet -->
                  <template v-if="hasMobileWallet">
                    <mw-user-info-form @prev-clicked="goToStep(steps.CHOOSE_PM_TYPE)"
                      :class="{ 'd-none': selectedType?.type != typesEnum.MOBILE_WALLET.type }"
                      :pm-methods="paymentMethods[typesEnum.MOBILE_WALLET.type]">
                    </mw-user-info-form>
                  </template>
                  <!-- Visa/Master cards. -->
                  <template v-if="hasVisaMaster">
                    <card-user-info-form @prev-clicked="goToStep(steps.CHOOSE_PM_TYPE)"
                      :class="{ 'd-none': selectedType?.type != typesEnum.VISA_MASTERCARD.type }" :mode="'currency'"
                      :pm-methods="paymentMethods[typesEnum.VISA_MASTERCARD.type]" @submitting="toggleLoading" :isVisaMasterCard="isVisaMasterCard"></card-user-info-form>
                  </template>
                  <!-- Debit cards -->
                  <template v-if="hasDebitCard">
                    <card-user-info-form @prev-clicked="goToStep(steps.CHOOSE_PM_TYPE)"
                      :class="{ 'd-none': selectedType?.type != typesEnum.DEBIT_CARD.type }"
                      :pm-methods="paymentMethods[typesEnum.DEBIT_CARD.type]"></card-user-info-form>
                  </template>
                  <!-- PayPal -->
                  <template v-if="hasPayaPal">
                    <card-user-info-form :class="{ 'd-none': selectedType?.type != typesEnum.PAY_PAL.type }" :mode="'currency'"
                      :pm-methods="paymentMethods[typesEnum.PAY_PAL.type]"></card-user-info-form>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <login />
      <div class="text-center" style="padding-top: 10px;">
        <cancel-button />
      </div>
      <div class="text-center" style="padding-top: 10px;">
        <copyright class="copyright"></copyright>
      </div>
    </div>
      <!-- teleport to body -->
      <tz-popup v-model:show="showConfirmModal" closeable round close-icon-position="top-left" teleport="body" style="width: 96%; max-width: 400px;">
        <div style="max-width: 400px; width: 95%;" class="modal">
          <!-- Title -->
          <div class="title">
              <h1>{{ $t('confirmPayment') }}</h1>
          </div>
          <div style="max-width: 400px; width: 100%; margin: auto;">
            <tz-form style="margin-left: 15px;margin-right: 15px;" class="" @submit="onSubmit">
              <div v-if="transaction && transaction.amount" style="padding: 12px 0; display: flex; flex-direction: column; justify-content: center;">
                <tz-row v-if="transaction.payerOrgUser && transaction.payerOrgUser.netAmount" style=" width: 100%; align-items: center;" justify="center" class="p-5">
                  <span style="font-weight: 100; font-size: 1.8rem; margin-top: 2px; margin-bottom: 0px;margin-left: 2px; margin-right: 10px;">
                    <strong> {{ formatCurrency(transaction.payerOrgUser.netAmount, transaction.payerOrgUser.currencyCode)}} </strong>
                    <small style="font-size: 11px !important">{{transaction.payerOrgUser.currencyCode }}</small>
                  </span>
                </tz-row> 
                <div v-if="transaction.fee || transaction.payerOrgUser.currencyCode != transaction.currencyCode">
                  <tz-collapse v-model="openedFee" accordion>
                    <tz-collapse-item name="1">
                      <template #title>
                        <div class="text-center">{{ $t('seeDetail') }} <tz-icon name="question-o" color="#990099" /></div>
                      </template>
                      <template #right-icon>
                        <!-- <div class="text-center">{{ $t('seeDetail') }} <tz-icon name="question-o" color="#990099" /></div> -->
                      </template>
                      <tz-row style=" width: 100%;" justify="center" class="">
                        <tz-col span="12" class="text-right pr">
                          {{ $t('transactionAmount') }}:
                        </tz-col>
                        <tz-col span="12" class="pl">
                          <span style="font-weight: bold; color: #555"> 
                            {{ formatCurrency(transaction.amount, transaction.currencyCode)}}
                            <small style="font-size: 11px !important">{{transaction.currencyCode }}</small>
                          </span>
                        </tz-col>
                      </tz-row>
                      <tz-row style=" width: 100%;" justify="center" class="" v-if="transaction.payerOrgUser.currencyCode != transaction.currencyCode">
                        <tz-col span="12" class="text-right pr">
                          
                        </tz-col>
                        <tz-col span="12" class="text-left pl">
                          <div class="" style="font-weight: bold; color: #555">
                            ({{ formatCurrency(transaction.payerOrgUser.amount, transaction.payerOrgUser.currencyCode) }} <small style="font-size: 11px">{{ transaction.payerOrgUser.currencyCode }}</small>)
                          </div>
                        </tz-col>
                      </tz-row>
                      <tz-row v-if="transaction.forex">
                        <tz-col span="12" class="text-right pr">
                          {{ $t('forexRate') }}: 
                        </tz-col>
                        <tz-col span="12">
                          <div class="pl">
                            <span style="color: #555; font-weight: bold">  
                              {{ transaction.forex.rate }}
                            </span>
                          </div>
                        </tz-col>
                      </tz-row>
                      <tz-row style=" width: 100%;" justify="center" class="">
                        <tz-col span="12" class="text-right pr">
                          {{ $t('fee') }}: 
                        </tz-col>
                        <tz-col span="12">
                          <div class="pl"> 
                            <span style="font-weight: bold; color: #555">
                              {{ formatCurrency(transaction.payerOrgUser.fee, transaction.payerOrgUser.currencyCode) }} 
                              <small style="font-size: 11px">{{ transaction.payerOrgUser.currencyCode }}</small>
                            </span>
                          </div>
                        </tz-col>
                      </tz-row>
                      <tz-row v-if="transaction.payerOrgUser.bonusAmount">
                        <tz-col span="12" class="text-right pr">
                          {{ $t('bonus') }}: 
                        </tz-col>
                        <tz-col span="12">
                          <div class="pl">
                            <span style="font-weight: bolder; color: #555">
                              {{ formatCurrency(transaction.payerOrgUser.bonusAmount, transaction.payerOrgUser.currencyCode) }} <small>{{ transaction.payerOrgUser.currencyCode }}</small>
                            </span>
                          </div>
                        </tz-col>
                      </tz-row>
                    </tz-collapse-item>
                  </tz-collapse>
                </div>
                <tz-row  style=" width: 100%;" justify="center" class="p-5">
                  <span style="font-weight: 100; font-size: 1rem; margin-top: 8px; margin-bottom: 8px;margin-left: 10px; text-align: center;">
                    {{ $t('enterYourPaymentPin') }}</span>
                  <pin-input @onUpdatePin="updatePin" v-if="renderPins"/>
                </tz-row>
              </div>

              <div style="margin: 16px; display: flex;">
                  <tz-button style="padding: 0px;" :loading="loading" :loading-text="$t('processing')"  round block type="primary" color="#922690" @click="processPayment()" native-type="submit">
                      {{ $t('pay') }} 
                  </tz-button>
              </div>
            </tz-form>
          </div>


          <div class="login-text text-center" style="padding-bottom: 30px;">
            <a href="#" style="font-weight: bolder;" @click="showForgotPin">{{ $t('forgotPaymentPin') }}?</a>
          </div>
        </div>
      </tz-popup>

      <tz-popup v-model:show="showForgotPaymentPin" closeable round close-icon-position="top-left" teleport="body">
      <!--  -->
      <ForgotPaymentPin :locale="lang"  @pin-changed="onPinChanged"></ForgotPaymentPin>
      <!--  -->
    </tz-popup>

  </div>
</template>

<script>
import { Notify,Icon } from 'tzui-vue';
import pmSelectMixin from '../../mixins/pm-select.mixin';
import transactionMixin from '../../mixins/transaction.mixin';
import { loadPaymentMethods, createTransaction, capturePaymentBalance, updateTransaction } from "../../api";
import websdkStore from '../../websdk.store';
import PinInput from './PinInput.vue'
import { getLocal, formatCurrency } from '../../../../../utils';
import UserInfo from './UserInfo.vue';
import Login from './Login.vue';
import TransactionAmount from './TransactionAmount.vue';
var emitter = require("tiny-emitter/instance");

import ForgotPaymentPin from "@/views/Components/ForgotPaymentPin/index.vue";
import CancelButton from '../../../CancelTransaction/CancelButton.vue';

const MOBILE_STEPS = {
  CHOOSE_PM_TYPE: 1,
  KEY_INFOS: 2
};

export default {
  name: "TzPayWebMobile",
  components: {
    ForgotPaymentPin,
    CancelButton,
    Notify,
    Icon,
    PinInput,
    TransactionAmount,
    UserInfo,
    Login  
  },

  mixins: [pmSelectMixin, transactionMixin],
  watch: {
    selectedType(data){
      //console.log("pm selected:  ", data);
      // if(data && data.type){
      //   this.goToStep(this.steps.KEY_INFOS);
      // }
    },
    allPaymentMethods(data){
      this.processPaymentMethods(data);
    }
  },
  data() {
    return {
      showForgotPaymentPin: false,
      renderPins: true,
      openedFee: '',
      formatCurrency,
      steps: MOBILE_STEPS,
      currentStep: MOBILE_STEPS.CHOOSE_PM_TYPE,
      showConfirmModal: false,
      tranzakBalance: {},
      payoutBalance: {},
      transaction: {},
      trans: websdkStore.trans.en,
      linkedPMs: [],
      tzpayToken: null,
      userInfo: {},
      isPIN: false,
      isVisaMasterCard: false,
      pin: "",
      loading:false,
    };
  },


  mounted() {
    this.trans = websdkStore.trans[websdkStore.lang];
    // var user = getLocal("tzpay-user");
    // if (user != undefined) {
    //   var objUser = user;
    //   this.tzpayToken = true;
    //   //console.log("User found");
    //   console.log(objUser);
    //   this.userInfo = objUser;
    // } else {
    //   //console.log("No user");
    // }

    this.verifyLogin();

    this.$nextTick(function () {
        setTimeout(() => {
          // if(websdkStore && websdkStore.paymentMethods && websdkStore.paymentMethods.length) {
          //   this.processPaymentMethods(websdkStore.paymentMethods);
          //   return;
          // }
          // this.loadPM();

          }, 1000)
    });

  emitter.on('user-logged-in', this.userLoggedIn);

  },

  methods: {
    reRenderPins(){
      this.renderPins = false;
      setTimeout(() => this.renderPins = true, 1000);
    },
    onPinChanged(){
      this.showForgotPaymentPin = false;
      this.pin = '';
      this.reRenderPins();
    },
    showForgotPin(){
      if(websdkStore.userInfo){
        this.showConfirmModal = false;
        this.showForgotPaymentPin = true;
      }
    },
    verifyLogin(){
      var user = getLocal("tzpay-user");
      var token = getLocal("tzpay-token");
      if(user && user != 'null' && user != 'undefined' && token && token != 'undefined' && token != 'null'){
        this.tzpayToken = true;
        this.userInfo = user;
      }
      //console.log("Available tz pay token here", this.tzpayToken);
    },
    userLoggedIn(e){
      //console.log('user logged in here:    ', e);
      this.loadPM();
      this.verifyLogin();
    },
    toggleLoading(value){
      if(value){
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    onSubmit(e){},
    updatePin(newPin){
      //console.log("The pin is", newPin);
      this.pin = newPin;
    },
    goToStep(nextStep) {
      if(this.selectedType && this.selectedType.type == this.typesEnum.VISA_MASTERCARD.type){
        const token = getLocal('tzpay-token');
        if(token){
          this.isVisaMasterCard = true;
          return
        }
      }
      this.currentStep = nextStep;
    },
    paymentFailedHandler(err){
      this.loading = false;
      console.log(err);
      Notify({
        type: 'danger',
        duration: 10000,
        message: this.trans.anErrorOccurredWhileProcessingPleaseRetry,
      });
    },
    processTranzakBalanceTransaction(res = {}){
      if (res && res.success && res.data) {
        this.transaction = res.data;
        websdkStore.paymentTransaction = res.data;
        websdkStore.paymentInfo = res.data;
        this.showConfirmModal = true;
        this.pin = '';
      } else {
        this.loading = false;
        Notify({
          type: 'danger',
          duration: 10000,
          message: res.errorMsg,
        });
      }
    },
    processPaymentMethods(methods){
      this.curPaymentMethods = methods;
      this.tzpayToken = true;
      this.tranzakBalance = methods.filter(pm => pm.accountType == 1)[0];
      this.payoutBalance = methods.filter(pm => pm.accountType == 15)[0];
      this.linkedPMs = methods.filter(pm => pm.accountType == 6);
    },
    async loadPM(){
      //console.log('calling from small devices...:::::::::::::::::::::::::::::::::::::::::.......................:::::::::::::::::::::>>>>>>>>>>>>>>>>>>>');
      this.paymentFlow = websdkStore.paymentFlow;
      const pmResult = await loadPaymentMethods(websdkStore.paymentFlow.flowId);
      if (pmResult && pmResult.success) {
        websdkStore.paymentMethods = pmResult.data;
        this.processPaymentMethods(pmResult.data);
      }
    },
    payWithBalance(account){
      this.openedFee = '';
      //console.log("Pay with balance", websdkStore);
      if(account.balance > websdkStore.paymentFlow.amount){


        if( websdkStore && websdkStore.paymentTransaction && websdkStore.paymentTransaction._id && websdkStore.paymentTransaction.status != -40 && !websdkStore.paymentTransaction.disablePayerAccountSelection){
          const transaction = websdkStore.paymentTransaction;
          updateTransaction(transaction._id, account.id ).then(res=>{
            this.processTranzakBalanceTransaction(res);
          }).catch(err=>{
            this.paymentFailedHandler(err);
          })
          return;
        }
        // return false;

        createTransaction( websdkStore.paymentFlow.flowId, account.id, null, null, window.location.origin +'/pred/'+websdkStore.paymentFlow.flowId )
        .then((res) => {
          this.processTranzakBalanceTransaction(res);
        }).catch(err => {
          this.paymentFailedHandler(err);
        });
      }else{
        Notify({
          duration: 5000,
          message: this.trans.theBalanceIsNotSufficient,
          type: 'warning'
        });
      }
    },
    processPayment(){
      if (!this.pin.trim().length) {
        Notify({
            type: 'warning',
            duration: 5000,
            message: this.trans.pleaseEnterYourPin,
          });
        return false;

      }
      this.loading = true;
      capturePaymentBalance(websdkStore.paymentTransaction._id, this.pin).then((res) => {
        this.loading = false;
        if (res.success && res.data) {
          console.log(res);
          this.getTransactionAndContinue(res.data.transactionId ?? websdkStore.paymentTransaction._id, 'success');
        }else{
          this.showCancel = false;
          this.showRefresh = false;
          Notify({
            type: 'warning',
            duration: 5000,
            message: res.errorMsg,
          });
          }
      }).catch(err => {
        this.loading = false;
        this.showCancel = false;
        this.showRefresh = false;
        console.log(err);
        Notify({
          type: 'danger',
          duration: 10000,
          message: this.trans.anErrorOccurredWhileProcessingPleaseRetry,
        })
      });
    },

    processTransactionWithLinkedPaymentMethod(res, linkedPM){
      //console.log("res",res);
      if (res.data.status < 0) {
        websdkStore.paymentResult = res.data;
        return this.$router.replace({ name: "failed" });
      } else if (res.data.status == 40) {
        websdkStore.paymentResult = res.data;
        return this.$router.replace({ name: "success" });
      }
      if (res && res.success && res.data) {
        websdkStore.paymentTransaction = res.data;
        websdkStore.paymentInfo = res.data;
        websdkStore.isMobile = true;
        websdkStore.userPhone = linkedPM.partnerAccountHolderId;
        websdkStore.paymentMethod = linkedPM;
        //websdkStore.pipType = 'card';
        //console.log("websdkStore",websdkStore);
        this.$router.replace({ name: "confirm-tnx" });
      } else {
        this.loading = false;
        Notify({
          type: 'danger',
          duration: 10000,
          message: res.errorMsg,
        });
      }
    },

    payWithLPM(linkedPM){
      if(!linkedPM || !linkedPM.accountId){
        this.paymentFailedHandler(null);
        return;
      }
      this.loading = true;
      if( websdkStore && websdkStore.paymentTransaction && websdkStore.paymentTransaction._id && websdkStore.paymentTransaction.status != -40 && !websdkStore.paymentTransaction.disablePayerAccountSelection){
        const transaction = websdkStore.paymentTransaction;
        const phone = linkedPM?.type == 4 ? linkedPM?.partnerAccountHolderId: undefined;
        updateTransaction(transaction._id, linkedPM?.accountId, phone).then(res=>{
          this.processTransactionWithLinkedPaymentMethod(res, linkedPM );
        }).catch(err=>{
          this.paymentFailedHandler(err);
        })
        return;
      }

      createTransaction(
        websdkStore.paymentFlow.flowId,
        linkedPM?.accountId,
        linkedPM?.partnerAccountHolderId ?? this.userInfo?.phoneNumber,
        this.userInfo?.email,
        window.location.origin +'/pred/'+websdkStore.paymentFlow.flowId
      ).then((res) => {
        this.processTransactionWithLinkedPaymentMethod(res, linkedPM );
      }).catch(err => {
        this.paymentFailedHandler(err);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  overflow: hidden;
}
.card{
  background-color: #fff;
  background: linear-gradient(#f8f8f8, #fff);
  box-shadow: 0 2px 4px -2px rgba(0,0,0,0.4);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  margin: 0.6rem;
}

.title,
.title * {
    text-align: center;
}

.title h1 {
    // color: #141867;
    color: rgb(146, 38, 144);
    padding: 8px 0;
    font-size: 22px;
}


.p-4,
.pb-4,
.py-4 {
  padding-bottom: 4px;
}

.m-0,
.my-0,
.mb-0 {
  margin-bottom: 0;
}

.m-0,
.my-0,
.mt-0 {
  margin-top: 0;
}

.m-4,
.mb-4,
.my-4 {
  padding-bottom: 4px !important;
}

.tz-web__container {
  height: 100vh;
  width: 100vw;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.tz-payment__section {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #EEEEEE;
  // padding: 16px;
  overflow: scroll;
}


.tz-payment__section_container {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 50px;
  justify-content: center;
}

.tz-payment__section_title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
}

// @media only screen and (max-width: 568px) {
//   .tz-payment__section {
//     height: 100vh;
//     transform: none;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     padding: 0;
//   }


//   .tz-payment__section_title {
//     &.title {
//       margin-bottom: 8px;
//     }

//     flex-direction: column;
//     margin-bottom: 20px;
//   }
// }

.tz-payment__section_title .title {
  color: #606060;
  float: left;
  font-size: 1.4em;
}

.tz-payment__section_title .notice {
  color: #CACAC9;
  font-size: .9rem;
}

.tz-payment__section_content {
  display: flex;
  justify-content: center;
}

.tz-selectable:not(.selected):hover {
  transform: scale(1.03);
}

.tz-selectable.selected {
  border-color: #922690;
}

.tz-selectable>.badge {
  display: inline-block;
  position: absolute;
  border-radius: 100%;
  background: #922690;
  text-align: center;
  color: white;
}

.tz-payment__section_form {
  display: grid;
  grid-template-rows: 40px 1fr;
}

.tz-payment__section_form__title {
  color: #606060;
  border-bottom: 1px solid #E1E0E1;
}

.tz-payment__section_form__title * {
  margin: 12px 0;
}

.tz-payment__section_form__body {
  display: flex;
  flex-direction: column;
  min-height: 200px;
}

/* Width */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar:hover {
  width: 10px;
  height: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 2px;
}

/* 
.tz-payment__section_form_body__method-list_item {
  width: 68px;
  height: 40px;
  border: 1px solid #919191;
} */
</style>