<template>
  <div class="mw-user-info__container">
    <template v-if="step == MW_STEPS.CHOOSE_PM">
      <payment-method-list @pm-selected="handlePmSelection" @country-changed="handleCountryChange" :items="pmMethods">
      </payment-method-list>
      <div :style="{ gridTemplateColumns: isMobile ? '1fr 1fr' : 'fr' }" style="display: grid; grid-gap: 8px;">
        <tz-button v-if="isMobile" type="danger" plain :text="trans.backForm" @click="backToList"></tz-button>
        <tz-button color="#922690" type="primary" :text="trans.next" :disabled="!(userInfo.operator?.accountId)"
          @click="nextClicked">
        </tz-button>
      </div>
      <!-- 
      <tz-button color="#922690" type="primary" :text="$t('next')" :disabled="!(userInfo.operator)" @click="nextClicked">
      </tz-button> -->
    </template>

    <template v-else>
      <div style="padding: 12px 0; display: flex; flex-direction: column; justify-content: center;">
        <span style="font-weight: 100; font-size: .76rem;  padding-bottom: 5px;"> {{ trans.paymentMethod }} :</span>
        <tz-field style="align-items: center;color:#323233;"  class="mb-20" v-model="userInfo.operator.shortName" autofocus clearable
          :left-icon="userInfo.operator.avatarUrl" :placeholder="trans.paymentMethod" readonly />

        <span style="font-weight: 100; font-size: .76rem; padding-bottom: 5px;"> {{ trans.phoneNumber }} :</span>
        <vue-tel-input style="color:#323233; padding: 5px;" class="vue-tel-input-custom p-2" v-if="showPhone" id="acMobile" v-model="userInfo.phone"
                        v-on:country-changed="countryChanged" v-on:validate="phoneValidation" v-bind="bindProps">
          <template slot="arrow-icon">
            <span class="vti__dropdown-arrow">&nbsp;▼ </span>
          </template>
        </vue-tel-input>

        <!-- <template v-if="!isCmMethod()">
          <span style="font-weight: 100; font-size: .76rem; padding-bottom: 5px; padding-top: 5px;">{{ trans.email }} :</span>
          <tz-field style="margin-bottom: 8px;" placeholder="example@domain.com" autofocus required clearable
            left-icon="envelop-o" v-model="userInfo.email" />
        </template> -->

      </div>
      <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 8px;">
        <tz-button type="danger" plain :text="trans.backForm" @click="prevClicked"></tz-button>
        <tz-button type="primary" color="#922690" :loading="loading" :disabled="!isValidForm()" :text="$t('submit')" @click="submitClicked" />
      </div>
    </template>
  </div>
</template>

<script>
import { Notify } from 'tzui-vue';
import { isMobile } from '../../../../../utils';
import websdkStore from '../../websdk.store';
import { createTransaction, updateTransaction } from '../../api';
import userInfoMixin from '../../mixins/user-info.mixin';
import {VueTelInput} from 'vue-tel-input';
import { CMR_COUNTRY_CODE, REGEXP_DEFAULT_PHONE, validateCmrPhone, validateEmail } from '../../utils';
import PaymentMethodList from './PaymentMethodList.vue';

const MW_STEPS = {
  CHOOSE_PM: 1,
  ENTER_INFOS: 2
};

export default {
  components: {
    PaymentMethodList,
    VueTelInput
  },
  props: {
    pmMethods: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [userInfoMixin],
  mounted(){
    this.trans = websdkStore.trans[websdkStore.lang];
  },
  data() {
    return {
      loading: false,
      showPhone: true,
      step: MW_STEPS.CHOOSE_PM,
      phoneIsValid: false,
      MW_STEPS,
      userInfo: {...JSON.parse(JSON.stringify(websdkStore.userInfo)), phone: ''},
      trans: websdkStore.trans.en,
      isMobile: isMobile(),
      CMR_COUNTRY_CODE,
      bindProps: {
        mode: "international",
        validCharactersOnly: true,
        onlyCountries: ['CM'],
        defaultCountry: 'CM',
        inputOptions: {
          placeholder: ["Tel"],
          required: true,
          styleClasses: ['form-control']
        },
      },
    }
  },
  methods: {
    countryChanged(params) {
      console.log(params);
      this.country = params.name;
    },

    phoneValidation(params) {
      if (params.valid) {
            this.phoneIsValid = true;
            //console.log("Phone valid");
        } else {
            this.phoneIsValid = false;
            //console.log("Phone invalid");
        }
    },
    isCmMethod() {
      return (
        !!this.userInfo.operator &&
        !!this.userInfo.operator.accountId &&
        (this.userInfo.operator.countryCode == CMR_COUNTRY_CODE)
      );
    },
    nextClicked() {
      this.step = MW_STEPS.ENTER_INFOS;
      this.addCountryCodeToUserPhone();
    },
    prevClicked() {
      this.step = MW_STEPS.CHOOSE_PM;
      this.removeCountryCodeToUserPhone();
    },
    backToList() {
      this.$emit('prev-clicked');
    },
    rerenderPhone() {
      this.showPhone = false;
      setTimeout(() => this.showPhone = true, 250);
    },
    handleCountryChange(country) {
      const code = country? country.code: 'CM'
      this.userInfo.phone = country.dial_code || country.dial;
      this.bindProps =  {
        mode: "international",
        validCharactersOnly: true,
        onlyCountries: [code],
        defaultCountry: code,
        enabledCountryCode: true,
        inputOptions: {
          defaultCountry: code,
          autofocus: true,
          placeholder: ["Tel"],
          required: true,
          styleClasses: ['form-control']
        },
      },
      this.country = country;
      this.rerenderPhone();
    },
    handlePmSelection(value) {
      this.$nextTick(() => {
        this.userInfo.operator = value;
      });
      websdkStore.paymentMethod = value;
    },
    validatePhone() {
      if (this.userInfo.operator) {
        if (this.userInfo.operator.validationMask) {
          const regExp = new RegExp(this.userInfo.operator.validationMask);

          return regExp.test(this.userInfo.phone);
        } else if (this.userInfo.operator.countryCode == CMR_COUNTRY_CODE) {
          return validateCmrPhone(this.userInfo.phone);
        }
      }

      return REGEXP_DEFAULT_PHONE.test(this.userInfo.phone);
    },
    validateEmail() {
      if (this.userInfo.email) {
        return validateEmail(this.userInfo.email);
      }

      return true;
    },
    isValidForm() {
      return this.phoneIsValid && this.validateEmail();
      // return this.validatePhone() && this.validateEmail();
    },
    processTransaction(res){
      this.loading = false;
      if (res && res.success && res.data) {
        if (res.data.status < 0) {
          websdkStore.paymentResult = res.data;
          return this.$router.replace({ name: "failed" });
        } else if (res.data.status == 40) {
          websdkStore.paymentResult = res.data;
          return this.$router.replace({ name: "success" });
        }
        websdkStore.paymentTransaction = res.data;
        websdkStore.paymentInfo = res.data;
        return this.$router.replace({ name: "confirm-tnx" });
      }else if(res && res.errorMsg){
        Notify({
          message: res.errorMsg,
          duration: 5000,
          type: 'warning'
        });
      }
    },
    failedTransaction(err){
      this.submittingPmEvent();
      this.loading = false;
      console.log(err);
      Notify({
        type: 'danger',
        duration: 5000,
        message: this.$t('anErrorOccurredWhileProcessingPleaseRetry'),
      });
    },
    submitClicked() {
      this.loading = true;
      const phone = (this.userInfo.phone || '').replaceAll(' ','');
      console.log(this.dial)
      websdkStore.userPhone = phone;

      if( websdkStore && websdkStore.paymentTransaction && websdkStore.paymentTransaction._id && websdkStore.paymentTransaction.status != -40 && !websdkStore.paymentTransaction.disablePayerAccountSelection ){
        updateTransaction(
          websdkStore.paymentTransaction._id, 
          websdkStore.paymentMethod.accountId,
          phone,
        ).then(res=>{
          this.processTransaction(res);
        }).catch(err=>{
          this.failedTransaction(err);
        });
        return;
      }


      createTransaction(
        websdkStore.paymentFlow.flowId,
        websdkStore.paymentMethod.accountId,
        phone,
        this.userInfo.email
      ).then((res) => {
        this.processTransaction(res);
      }).catch(err => {
        this.failedTransaction(err);
      });
    }
  },
}
</script>

<style scoped lang="scss">
.mw-user-info__container {
  display: grid;
  height: 100%;
  max-height: 400px;
  overflow: hidden;
  grid-template-rows: 1fr 44px;
}
</style>