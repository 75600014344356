
import TransactionLoader from '@/views/Components/websdk/Components/shared/TransactionLoader.vue';
import SelectPaymentMode from '@/views/Components/websdk/Components/shared/SelectPaymentMode.vue';
import PaymentInfo from '@/views/Components/websdk/Components/shared/PaymentInfo.vue';
import ApprovalRequired from '@/views/Components/websdk/Components/shared/ApprovalRequiredPage.vue';
import PaymentResult from '@/views/Components/websdk/Components/web/PaymentResult.vue';
import PaymentCompletionAwaiter from '@/views/Components/websdk/Components/shared/PaymentCompletionAwaiter.vue';
import PartnerResultPage from '@/views/Components/websdk/Components/shared/PartnerResultPage.vue';
import Test from '@/views/Components/websdk/test.vue';
import CancelledTransaction from '../views/Components/CancelTransaction/index.vue';
import PaymentReceipt from '../views/Components/PaymentReceipt/PaymentReceipt.vue';

// import { isMobile } from '../utils'

const NotFound = {
  template: '<div style="text-algin:center; margin: 100px 20px"><h4>Page Not Found</h4></div>',
}

const routes = [
  {
    name: "entry",
    path: '/', component: TransactionLoader,
    meta: {
      transition: 'slide-fade',
      canEnter: true
    }
  },
  // {
  //   name: "confirm-pr",
  //   path: '/confirm', component: PaymentInfo,
  //   props: true,
  //   meta: {
  //     next: 'select-mode',
  //     transition: 'slide-fade',
  //     hideCancel: true
  //   }
  // },
  {
    name: 'select-mode',
    path: '/select-mode',
    component: SelectPaymentMode,
    meta: {
      transition: 'slide-fade'
    }
  },
  {
    name: "confirm-tnx",
    path: '/check-details', component: PaymentInfo,
    props: true,
    meta: {
      prev: 'select-mode',
      next: 'pip',
      transition: 'slide-fade'
    }
  },
  {
    name: "approval-required",
    path: '/approval-required', component: ApprovalRequired,
    props: true,
    meta: {
      transition: 'slide-fade'
    }
  },
  {
    name: 'pip',
    path: '/payment-in-progress', component: PaymentCompletionAwaiter,
    meta: {
      transition: 'slide-fade',
      showDials: true,
    }
  },
  {
    name: 'card-pip',
    path: '/card-payment-in-progress', component: PaymentCompletionAwaiter,
    meta: {
      transition: 'slide-fade',
      showDials: false,
      type: 'card'
    }
  },
  {
    name: 'success',
    path: '/payment-success', component: PaymentResult,
    meta: {
      transition: 'slide-fade',
      success: true
    }
  },
  {
    name: 'failed',
    path: '/payment-failure', component: PaymentResult,
    meta: {
      transition: 'slide-fade',
      success: false
    }
  },
  {
    name: 'payment-cancelled',
    path: '/payment-cancelled', component: CancelledTransaction,
    meta: {
      transition: 'slide-fade',
      success: false
    }
  },
  {
    name: 'payment-receipt',
    path: '/payment-receipt', component: PaymentReceipt,
    meta: {
      transition: 'slide-fade',
      success: true
    }
  },
  {
    name: 'partner-redirect',
    path: '/pred/:rid', component: PartnerResultPage,
    props: true,
    meta: {
      transition: 'slide-fade',
      success: false,
      canEnter: true
    }
  },
  {
    name: 'test-route',
    path: '/test/:id', component: Test,
    props: true,
    meta: {
      canEnter: true
    }
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

export default routes;