<template>
    <div class="app-inner-wrapper">
        <div id="language-swap" v-if="renderLang">
            <div class="display-language">
                <span class="selected-lang" @click="showLangs = !showLangs">
                    {{ lang }} <tz-icon name="arrow-down" />
                </span>
                <div class="all-langs" v-if="showLangs">
                    <div class="lang-item" v-for="item in allowedLangs" :key="item" @click="selectLanguage(item)">
                        {{ item }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="howLoader" style="
                position: fixed;
                height: 100vh;
                width: 100vw;
                left: 0;
                top: 0;
                z-index: 1001;
            ">
            <tz-loading type="spinner" color="#a405af"
                style="position: absolute; left: 50%; top: 50%; margin-left: -15px; margin-top: -30px; background: rgba(0,0,0,0.2), z-index:10000" />
        </div>
         <router-view v-slot="{ Component, route }">
            <transition :name="route.meta?.transition || 'fade'">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>
<script>
import { getAppId, setLocal, getLocal, showLoader  } from './utils/index';
import PaymentCompletionAwaiter from '@/views/Components/websdk/Components/shared/PaymentCompletionAwaiter.vue';
import LoadingTransaction from './views/Components/websdk/Components/shared/TransactionLoader.vue';
import PaymentResult from './views/Components/websdk/Components/web/PaymentResult.vue';

// import WebSdk from '@/views/Components/websdk/index.vue';

var emitter = require("tiny-emitter/instance");

export default {
    components: {
        PaymentCompletionAwaiter,
        LoadingTransaction,
        PaymentResult,
        // WebSdk,
    },
    name: "App",
    data() {
        return {
                howLoader: false,
                showLangs: false,
                renderLang: true,
                lang: 'en',
                langs: ['en', 'fr']
        };
    },
    created() {
        emitter.on("bt-toggle-loader", (isHide) => {
            setTimeout(() => {
                this.howLoader = !isHide;
            }, 350);
        });
        emitter.on("refresh-app", () => {
            const token = getLocal('tzpay-token');
            const user = getLocal('tzpay-user');
            if(token) {
                setLocal("tzpay-tmp-token", token);
            }
            if(user) {
                setLocal("tzpay-user", user);
            }
            showLoader();
            this.$router.go();
        });
    },
    mounted(){
        
        let lang = getLocal('lang');
        if(lang != 'fr'){
            lang = 'en'
        }
        this.lang = lang;
    },
    computed: {
        allowedLangs(){
            return this.langs.filter(e=> e != this.lang)
        }
    },
    methods: {
        selectLanguage(lang){
            this.showLangs = !this.showLangs;
            if(this.lang != lang){
                this.lang = lang;
                setLocal('lang', lang);
                const current = new URL(location.href);
                current.searchParams.set('lang', lang);
                const newTitle = document.title;
                const nextState = { additionalInformation: 'updated language'};
                window.history.replaceState(nextState, newTitle, current);
                
                // location = location.href;
                emitter.emit('refresh-app');
            }
        },
        async loadAppData() {
            const appId = getAppId();
            await this.$store.dispatch("retrieveAppInfo", appId);

            this.appInfo = this.$store.state.appInfo;
        },
    }
};
</script>
<style lang="scss">
@import url('./assets/css/web-sdk.css');
// #app {
//     font-family: Avenir, Helvetica, Arial, sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     color: #2c3e50;
// }

// .app-inner-wrapper {
//     background: white;
// }
#language-swap{
    user-select: none;
    right: 0;
    text-align: right;
    position: absolute;
    font-weight: bolder;
    text-transform: capitalize;
    margin: 2px;
    z-index: 1000;
    .all-langs{
        position: absolute;
        cursor: pointer;
        right: 5px;
        z-index: 1;
        width: 60px;
        text-align: center;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        border-radius: 4px;
        .lang-item{
            padding: 4px;
            transition: all .3s linear;
            &:nth-child(1){
                border-bottom: 1px solid #eee;
            }
            &:hover{
                background: #ddd;
            }
        }
    }
    .selected-lang{
        cursor: pointer;
        padding: 5px;
        display: inline-block;
        white-space: nowrap;
        background: #fff;
        border-radius: 4px;
        width: 60px;
        z-index: 0;
        margin-top: 5.5px;
        margin-right: 5px;
        text-align: center;
    }
}
</style>
