import { getLocal, hideLoader, showLoader } from ".";

let counter = 0;

async function tzFetch(url, params = {}, method) {

  showLoader();
  counter+=1

  const token = getLocal('tzpay-token');
  const lang = getLocal('lang') || 'en';

  try {
    const requestParams = {
      // url,
      method: method || 'POST',
      headers: {
        "Content-type": "application/json",
        "Authorization": token ? `Bearer ${token}` : '',
        "X-Language": lang
      },
      body: method == 'GET' ? undefined : JSON.stringify(params)
    }

    const response = await fetch(url, requestParams);
    counter-=1;
    if(counter <= 0 ){
      hideLoader();
    }

    if (response.status !== 200) {
      //console.log('Looks like there was a problem. Status Code: ' +response.status);
      return;
    }

    // Examine the text in the response
    const jsonRes = await response.json();

    return jsonRes;
  } catch (error) {
    counter-=1;
    if(counter <= 0 ){
      hideLoader();
    }
    //console.log('Fetch Error :-S', error);
  }
}

function resolveHost() {
  switch (process.env.NODE_ENV) {
    case 'production':
      return 'https://api.tranzak.me';

    case 'sandbox':
      return 'https://sandbox-api.tranzak.me';

    default:
      return 'https://staging-api.tranzak.me';
  } 
}

//console.log("process.env=====", process.env);

const HOST = process.env.NODE_ENV == 'production' ? 'https://api.tranzak.me' : (process.env.NODE_ENV == 'sandbox' ? 'https://sandbox-api.tranzak.me' : 'https://staging-api.tranzak.me');
const noSandBox = process.env.NODE_ENV == 'sandbox'? 'https://api.tranzak.me': HOST;

const cortexBaseUrl = `${HOST}/fapi/gateway`;

export {
  tzFetch,
  cortexBaseUrl,
  HOST,
  noSandBox
};
