// let loader = document.getElementById('main-loader');
let loader;

export const getLocal = (name) => {
  try{
    if(window.localStorage && true){
      let result = localStorage.getItem(name);
      if(result){
        try{
          const data = JSON.parse(result);
          if(data && data != 'null' && data != 'undefined') return data;
          return undefined;
        }catch(e){
          return result;
        }
      }
    }
  }catch(e){
    return undefined;
  }
  return undefined;
}

export const setLocal = (name, value) => {
  try{
    if(window.localStorage && true && value){
      localStorage.setItem(name, JSON.stringify(value));
    }
  }catch(e){
    
  }
}

export const clearLocal = (name) => {
  try{
    if(window.localStorage && true){
      localStorage.removeItem(name);
    }
  }catch(e){

  }
}

export const getAppId = () => {
  const regex = /\/tzmp\/(\w+)/;
  const found = location.pathname.match(regex);

  if (found && found.length >= 2) {
    return found[1];
  }

  return "";
}

export const toFinancialNumber = (x = 0, val = 2) => {
  if (!x) return "";
  if (x > 1) return Number.parseFloat(x).toFixed(val);

  return Number.parseFloat(x).toFixed(1 - Math.floor(Math.log(x) / Math.log(10)));
  // alternative way below
  //var r = n.toFixed(20).match(/^-?\d*\.?0*\d{0,2}/)[0];
};

export const isValidCmNumber = (number) => {
  return /^6[25689]\d{7}$/.test(number);
}

export function isMobile() {
  return window.innerWidth <= 568 || window.innerHeight <= 400;
}

export function truncateTextTo(text, length) {
  if (!text) return null;

  if (!length) return text;

  if (`${text}`.length <= length) return text;

  return `${text}`.substr(0, length - 3) + '...';
}

export const formatCurrency = (value, currency = 'XAF', language = 'en-US') =>{
  return new Intl.NumberFormat(language, { style: 'currency', currency: currency }).formatToParts(value).map(
      p => p.type != 'literal' && p.type != 'currency' ? p.value : ''
  ).join('');
};

export const setLoader = () =>{
  loader = document.getElementById('main-loader');
};
export const hideLoader = () =>{
  if(loader){
    loader.style.display = 'none';
    return;
  }
  // setLoader();
};
export const showLoader = () =>{
  if(loader){
    loader.style.display = 'flex';
    return;
  }
  // setLoader();
};

setLoader();
