<template>
  <div class="container">
    <div class="container-wrapper">
      <div class="main-item">
        <div class="wrapper">
          <div class="title" :class="{ success: success, error: !success }">
            <template v-if="success">
              <span class="mb-20">{{ trans.thankYou }}</span>
              <svg width="40" height="40" fill="#58C04E" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 330"
                style="enable-background:new 0 0 330 330;" xml:space="preserve">
                <g>
                  <path d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300 c-74.44,0-135-60.561-135-135S90.56,30,165,30s135,60.561,135,135S239.439,300,165,300z" />
                  <path d="M226.872,106.664l-84.854,84.853l-38.89-38.891c-5.857-5.857-15.355-5.858-21.213-0.001 c-5.858,5.858-5.858,15.355,0,21.213l49.496,49.498c2.813,2.813,6.628,4.394,10.606,4.394c0.001,0,0,0,0.001,0 c3.978,0,7.793-1.581,10.606-4.393l95.461-95.459c5.858-5.858,5.858-15.355,0-21.213 C242.227,100.807,232.73,100.806,226.872,106.664z" />
                </g>
              </svg>
            </template>
            <template v-else>
              <span class="mb-20">{{ trans.OOPS }}</span>
              <svg fill="crimson" width="40" height="40" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 330"
                style="enable-background:new 0 0 330 330;" xml:space="preserve">
                <g>
                  <path d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165c90.982,0,165-74.019,165-165S255.982,0,165,0z M165,300 c-74.439,0-135-60.561-135-135S90.561,30,165,30c74.439,0,135,60.561,135,135S239.439,300,165,300z" />
                  <path d="M239.247,90.754c-5.857-5.858-15.355-5.858-21.213,0l-53.033,53.033l-53.033-53.033c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L143.788,165l-53.033,53.033c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.464,10.606-4.394l53.033-53.033l53.033,53.033 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.464,10.607-4.394c5.858-5.858,5.858-15.355,0-21.213L186.214,165 l53.033-53.033C245.105,106.109,245.105,96.612,239.247,90.754z" />
                </g>
              </svg>
            </template>
          </div>
          <p class="net-to-pay"> {{ payerAmount }}
            <template v-if="usesDifferentCurrency">
              <br />
              <span class="original-amount">( {{ totalAmountStr }} )</span>
            </template>
          </p>
          <p class="pr-message mb-20">{{ $t(successMessage) }}</p>
          <!-- <p v-if="showRedirectMessage && remainingSeconds" class="pr-redirect-message mb-30">{{ trans.youWillBeRedirectedIn }}
            <span>{{ remainingSeconds }}</span> {{ trans.seconds }}
            <span @click="navigateToCaller" class="navigate-link">{{ trans.redirectNow }}</span>
          </p> -->
    
          <div v-if="!success">
            <div class="line"></div>
            <div class="py-5 my-5 text-center">
              <tz-button plain type="warning" round size="small" class="pl pr my-1 failed-btns" @click="changePM">
                {{ $t('changePaymentMethod') }}
              </tz-button>
              <tz-button plain type="danger" round size="small" class="pl pr my-1 failed-btns" @click="navigateToCaller" v-if="transaction.returnUrl">
                {{ $t('closePayment') }}
              </tz-button>
            </div>
          </div>
          <div v-else style="padding: 5px;">
            <div class="d-flex" style="justify-content: space-between;">
              <div :class="`mt-10 w-100 p-10 ${!transaction.returnUrl? 'text-center':''}`">
                <tz-button color="#990099" round size="small" :to="{name: 'payment-receipt', params: {transactionId: transaction._id, transaction: JSON.stringify(transaction)}}" style="padding: 10px 20px;">{{ $t('viewPaymentReceipt') }} </tz-button>
              </div>
              <div class="mt-10 w-100 text-right-sm-left p-10" v-if="transaction.returnUrl">
                <tz-button plain color="#990099" round @click="navigateToCaller" size="small" style="padding: 10px 20px;">{{ $t('goBackToMerchant') }}</tz-button>
                <div>
                  <small>
                    {{ $t('youWillBeRedirectedTo') }} <span @click="navigateToCaller" class="cursor-pointer text-primary">{{ merchant }}</span>
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="tzp-table">
            <div class="tzp-row">
              <div class="tzp-col"> {{ trans.transactionID }}:</div>
              <div class="tzp-col"> {{ ID }}</div>
            </div>
            <div class="tzp-row">
              <div class="tzp-col"> {{ trans.date }}:</div>
              <div class="tzp-col"> {{ date }}</div>
            </div>
            <div class="tzp-row">
              <div class="tzp-col"> {{ trans.merchant }}:</div>
              <div class="tzp-col"> {{ merchant }}</div>
            </div>
            <div class="tzp-row">
              <div class="tzp-col"> {{ trans.amount }}:</div>
              <div class="tzp-col"> {{ totalAmountStr }} </div>
            </div>
            <div class="tzp-row" v-if="paymentMethod">
              <div class="tzp-col"> {{ trans.account }}:</div>
              <div class="tzp-col ellipsis-mid"> <img v-if="paymentMethodAvatar" :src="paymentMethodAvatar" height="36"
                  style="margin-right: 8px;">
                {{ paymentMethod }}
              </div>
            </div>
            <div class="tzp-row" v-if="hasFees">
              <div class="tzp-col"> {{ trans.fees }}:</div>
              <div class="tzp-col"> {{ feesStr }}</div>
            </div>
            <div v-if="hasBonus" class="tzp-row">
              <div class="tzp-col"> {{ trans.bonuses }}:</div>
              <div class="tzp-col"> {{ transaction.payerOrgUser.currencyCode }} {{ transaction.payerOrgUser.bonusAmount }}</div>
            </div>
            <div class="tzp-row" v-if="description">
              <div class="tzp-col"> {{ trans.description }}:</div>
              <div class="tzp-col cursor-pointer" @click="showFullDescription"> {{ description }}</div>
            </div>
            <div class="line"></div>
          </div>
          <copyright></copyright>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Notify } from 'tzui-vue';
import Copyright from '../shared/Copyright.vue';
import { toDateTime, formatCurrency, isMobile } from '../../utils';
import websdkStore from '../../websdk.store';
import { truncateTextTo } from '../../../../../utils';
export default {
  components: {
    Copyright
  },
  props: {
    returnUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      trans: websdkStore.trans.en,
      transaction: websdkStore.paymentResult,
      remainingSeconds: 10,
      showRedirectMessage: false,
      isMobile: isMobile()
    }
  },
  computed: {
    flow(){
      return websdkStore.paymentFlow || websdkStore.paymentInfo || {};
    },
    ID() {
      return this.transaction._id;
    },
    successMessage() {
      return this.success ? "yourPaymentWasSuccessful" : "yourPaymentWasUnsuccessful";
    },
    date() {
      return toDateTime(this.transaction.completedAt || this.transaction.createdAt);
    },
    totalAmountStr() {
      return formatCurrency(this.transaction.amount, this.transaction?.currencyCode);
    },
    feesStr() {
      return formatCurrency(this.transaction.payerOrgUser?.fee, this.transaction.payerOrgUser?.currencyCode);
    },
    merchant() {
      let name = null
      if(this.flow.customization && this.flow.customization.title){
        name = this.flow.customization.title
      }else if(this.flow.app && this.flow.app.name){
        name = this.flow.app.name;
      }
      name = truncateTextTo(name || this.transaction.beneDisplayName || this.transaction.beneName || this.transaction.beneRealName || this.transaction.beneUser?.displayName, 40);
      return name;
    },
    success() {
      return this.$route.meta?.success ?? false;
    },
    paymentMethod() {
      const method = this.transaction.payerAccount?.name;

      if (!method) return null;
      if (method.length <= 30) return method;

      return method.substr(0, 30 - 10) + '...' + method.substr(-4);
    },
    paymentMethodAvatar() {
      return this.transaction.payerAccount?.avatarUrl;
    },
    usesDifferentCurrency() {
      return this.transaction.payerOrgUser && this.transaction.payerOrgUser.currencyCode && this.transaction.payerOrgUser.currencyCode != this.transaction.currencyCode;
    },
    payerAmount() {
      console.log(this.transaction)
      // return formatCurrency(this.transaction.payerOrgUser.netAmount, this.transaction.payerOrgUser.currencyCode);
      return formatCurrency(this.transaction.amount, this.transaction.currencyCode);
    },
    hasFees() {
      return !!this.transaction.payerOrgUser?.fee;
    },
    hasBonus() {
      return !!this.transaction.payerOrgUser?.bonusAmount;
    },
    description() {
      return truncateTextTo(this.transaction.description, 20);
    },
  },
  mounted() {
    //console.log("Lang",websdkStore.lang);
    this.trans = websdkStore.trans[websdkStore.lang];
 
    if (websdkStore.canRedirect) {
      if(!this.success) return;
      setTimeout(() => {
        this.showRedirectMessage = true;
        const intervalId = setInterval(() => {
          this.remainingSeconds -= 1;

          if (this.remainingSeconds <= 0) {
            clearInterval(intervalId);

            // this.navigateToCaller();
          }
        }, 1000);
      }, 2000);
    }

  },
  methods: {
    changePM(){
      this.$router.replace('/select-mode');
    },
    showFullDescription() {
      Notify({
        duration: 10000,
        color: 'white',
        background: '#922690',
        message: this.transaction.description,
      });
    },

    tableStyle() {
      return this.isMobile ? { marginBottom: '44px' } : { marginBottom: '16px' };
    },
    appendParamToQueryString(url, name, value) {
      if (!url || !name) return url;

      return `${url}`.includes('?') ? `${url}&${name}=${value}` : `${url}?${name}=${value}`;
    },
    navigateToCaller() {
      //console.log("this.transaction===========",this.transaction);
      if (this.transaction?.returnUrl) {
        if (typeof this.transaction.returnUrl == 'string') {
          let fullUrl = this.flow && this.flow.cancelUrl? this.flow.cancelUrl: this.transaction.returnUrl;
          fullUrl = this.appendParamToQueryString(fullUrl, 'success', this.success);
          fullUrl = this.appendParamToQueryString(fullUrl, 'transactionId', this.transaction._id);
          fullUrl = this.appendParamToQueryString(fullUrl, 'requestId', this.transaction.flowId);

          window.location.replace(fullUrl);
        }
      }
      // if (`${this.returnUrl}`.trim().length > 0) {
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
$textColor: #404144;

* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
}
.failed-btns{
  margin: 3px;
  padding-left: 15px;
  padding-right: 15px;
}
.container {
  // position: absolute;
  // width: 100vw;
  // height: 100vh;
  // background: #eeeeee;
  // overflow-y: auto;
}

$wrapperPadding: 24px;

.container-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  .main-item{
    position: relative;
    overflow-y: auto;
    bottom: 0;
    top: 0;
    position: absolute;
    background: white;
  }
}


.wrapper {
  position: relative;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  min-height: 400px;
  height: auto;
  padding: $wrapperPadding;
  display: flex;
  flex-direction: column;
  overflow: auto;
  //box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.1);
}

.tzp-table {
  margin-bottom: 44px;
}


@media (max-width: 568px) {
  .wrapper {
    padding-bottom: 24px;
  }

  .title {
    margin-top: -72px;
  }

  .tz-payment__section_copyright {
    bottom: 30px;
  }

  @media (max-height: 569) {
    .tzp-col {
      height: 36px !important;
    }
  }


  .tzp-table {
    margin-bottom: 12px;
  }

  .wrapper {
    top: 0;
    left: 0;
    bottom: 0;
    // padding: 8px;
    padding-bottom: 0;
    right: 0;
    height: 100%;
    transform: none;
    justify-content: center;
  }
}

.pr-redirect-message {
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  color: $textColor;

  span {
    font-size: unset;
    font-weight: 600;
    color: #922690;
  }

  span.navigate-link {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      font-weight: 700;
    }
  }
}


.title {
  font-size: 32px;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  &.error {
    color: crimson !important;
  }

  &.success {
    color: #58C04E !important;
  }
}

.net-to-pay {
  padding-top: 20px;
  text-align: center;
  font-weight: 800;
  font-size: 24px;
  color: $textColor;
  -webkit-text-stroke: 1px black;

  .original-amount {
    font-weight: normal;
    font-size: 16px;
  }
}

.pr-message {
  margin-top: 20px;
  text-align: center;
  font-weight: 700;
  color: $textColor;
  text-transform: uppercase;
}
</style>