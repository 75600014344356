<template>
  <div>
    <div class="user-login-container" v-if="renderContent">
      <div v-if="userLoggedIn" class="login-text text-center" style="font-weight: bolder;">
        <a href="#" style="font-size: 15px; transform: scale(1) !important;" @click="showPopUp = true">{{ $t('signIn') }}</a>&nbsp;<span class="text-sigin">{{ $t('intoYourTranzakAccount') }}.</span>
  
        <!-- pop up -->
        <tz-web-login :locale="lang" :show="showPopUp" @close="e => { showPopUp = false }" @login-successful="loginSuccessful" />
      </div>
    </div>
  </div>
</template>
<script>
import {  getLocal, setLocal, showLoader } from '../../../../../utils';
var emitter = require("tiny-emitter/instance");
import websdkStore from '../../websdk.store';

  export default {
    name: 'UserLogin',
    data(){
      return{
        lang: getLocal('lang') || 'en',
        showPopUp: false,
        tzpayToken: null,
      }
    },
    mounted(){
      const exitingToken = getLocal("tzpay-token");

      if (exitingToken) {
        this.tzpayToken = exitingToken.trim();
      } else {
        this.tzpayToken = null;
      }
    },
    computed: {
      userLoggedIn(){
        return (!this.tzpayToken  || this.tzpayToken.length < 20) ? true: false;
      },
      renderContent(){
        if(websdkStore && websdkStore.paymentFlow && !websdkStore.paymentFlow.customization){
          return true
        }
        return false
      }
    },
    methods: {
      loginSuccessful(result) {
        setLocal("tzpay-token", result.token);
        setLocal("tzpay-user", result.user);
        this.showPopUp = false;
        emitter.emit('refresh-app',true);
      },
    }
  }
</script>
<style lang="scss">
  .user-login-container{
    .login-text {
      padding: 20px;
      color: #363771;
      font-size: 12px;
      flex-wrap: wrap;
      left: 44px;
      color: #606060;
      bottom: 8px;
    }
  }
</style>