export function validateEmail(input) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(input);
}

export const REGEXP_MTN_CM = /^(\+237)*6(5[0-4]|7[0-9]|8[0-3])\d{6}$/gi;
export const REGEXP_ORANGE_CM = /^(\+237)*6(5[5-9]|9[0-9])\d{6}$/gi;
export const REGEXP_DEFAULT_PHONE = /^(\+\d{1,3})*\d{4,}$/;

export function validateCmrPhone(input) {
  //Test MTN & Orange phone numbers.
  return (REGEXP_MTN_CM.test(input)) || (REGEXP_ORANGE_CM.test(input));
}

export const PAYMENT_METHOD_TYPES = Object.freeze({
  /** Master and Visa cards. */
  VISA_MASTERCARD: {
    type: "1",
    desc: "Visa/Mastercard | Credit Card",
    rank: 3,
    logos: [
      {
        src: "https://fms.tranzak.me/cdn/pf/naqtp6aso96wbp5b_undefined109555.06189120407visa.png",
        // src: "https://fms.tranzak.me/cdn/pf/m1w6d1im27w2nmuk_undefined101366.00387077172visacard.png",
        alt: 'Visa card'
      },
      {
        src: "https://fms.tranzak.me/cdn/pf/3hnwspyutxh33871_undefined102927.0268251881mastercard.png",
        // src: "https://fms.tranzak.me/cdn/pf/6upfb653iiesvu69_undefined100125.11344246mastercard.png",
        alt: 'Mastercard',
        title: ''
      },
    ]
  },
  /** Enrolled banks' debit cards. */
  DEBIT_CARD: {
    type: "2",
    desc: "Debit card",
    rank: 4,
    logos: [
      {
        src: `
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  width="68px" height="40px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M501.55,446.146v-23.014c0-5.77-4.679-10.449-10.449-10.449h-11.494v-25.077c0-5.77-4.679-10.449-10.449-10.449h-2.09
			V271.675h2.09c5.77,0,10.449-4.679,10.449-10.449v-25.077h10.449c5.77,0,10.449-4.679,10.449-10.449v-23.014h1.045
			c4.569,0,8.608-2.969,9.972-7.33c1.365-4.36-0.263-9.102-4.018-11.705L261.955,13.357c-3.582-2.484-8.329-2.484-11.91,0
			L4.495,183.651c-3.754,2.603-5.382,7.345-4.018,11.705c1.365,4.361,5.403,7.33,9.972,7.33h1.045V225.7
			c0,5.77,4.679,10.449,10.449,10.449h10.449v25.077c0,5.77,4.679,10.449,10.449,10.449h2.09v105.481h-2.09
			c-5.77,0-10.449,4.679-10.449,10.449v25.077H20.899c-5.77,0-10.449,4.679-10.449,10.449v23.014
			c-5.771,0-10.449,4.679-10.449,10.449v33.463c0,5.77,4.678,10.449,10.449,10.449h491.1c5.77,0,10.449-4.679,10.449-10.449v-33.463
			C511.999,450.824,507.32,446.146,501.55,446.146z M256,34.659l212.148,147.129H43.852L256,34.659z M42.842,215.251H32.393v-12.565
			h447.214v12.565h-10.449H42.842z M456.62,398.055h2.09v14.629h-43.885v-14.629h2.09H456.62z M427.363,377.157V271.675h18.808
			v105.482H427.363z M414.824,250.777v-14.628h43.885v14.628H414.824z M320.086,254.954H191.913c-5.77,0-10.449,4.679-10.449,10.449
			v147.28h-63.391v-25.077c0-5.77-4.679-10.449-10.449-10.449h-2.09V271.675h2.09c5.77,0,10.449-4.679,10.449-10.449v-25.077
			h275.852v25.077c0,5.77,4.679,10.449,10.449,10.449h2.09v105.481h-2.09c-5.77,0-10.449,4.679-10.449,10.449v25.077h-63.391
			v-147.28C330.535,259.633,325.856,254.954,320.086,254.954z M256,328.098c-5.77,0-10.449,4.679-10.449,10.449v74.136h-43.189
			V275.852h107.275v136.831h-43.189v-74.136C266.449,332.777,261.77,328.098,256,328.098z M95.086,398.055h2.09v14.629H53.291
			v-14.629h2.09H95.086z M65.829,377.156V271.675h18.808v105.481H65.829z M53.291,250.777v-14.628h43.885v14.628H53.291z
			 M480.652,433.582v12.565H31.348c0,0,0-12.565,0-12.565H480.652z M491.101,479.609H20.899v-12.565h470.202V479.609z"/>
	</g>
</g>
<g>
	<g>
		<path d="M255.999,294.156c-5.77,0-10.449,4.679-10.449,10.449v0.138c0,5.77,4.679,10.449,10.449,10.449
			s10.449-4.679,10.449-10.449v-0.138C266.448,298.834,261.769,294.156,255.999,294.156z"/>
	</g>
</g>
<g>
	<g>
		<path d="M266.449,99.936V76.93c2.76,2.284,4.477,5.46,4.477,8.968c0,5.77,4.679,10.449,10.449,10.449s10.449-4.679,10.449-10.449
			c0-15.064-10.703-27.833-25.375-32.022v-0.587c0-5.77-4.679-10.449-10.449-10.449s-10.449,4.679-10.449,10.449v0.587
			c-14.672,4.189-25.375,16.958-25.375,32.022s10.703,27.833,25.375,32.022v23.005c-2.76-2.284-4.477-5.46-4.477-8.968
			c0-5.77-4.679-10.449-10.449-10.449s-10.449,4.679-10.449,10.449c0,15.064,10.703,27.833,25.375,32.022v1.469
			c0,5.77,4.679,10.449,10.449,10.449s10.449-4.679,10.449-10.449v-1.469c14.672-4.189,25.375-16.958,25.375-32.022
			C291.824,116.893,281.121,104.126,266.449,99.936z M245.551,94.868c-2.76-2.284-4.477-5.46-4.477-8.969
			c0-3.51,1.718-6.684,4.477-8.968V94.868z M266.449,140.927v-17.938c2.76,2.284,4.477,5.46,4.477,8.968
			C270.926,135.467,269.208,138.643,266.449,140.927z"/>
	</g>
</g>
</svg>
`,
        alt: 'Debit card',
        isSvg: true,
        title: "Bank"
      },
    ]
  },
  /** Mobile money operators */
  MOBILE_WALLET: {
    type: "4",
    rank: 2,
    desc: "Telecom wallet",
    logos: [
      {
        // src: "https://fms.tranzak.me/cdn/pf/lwvn9eg8tow4afgl_undefined107011.09259646303mobile_pay.png",
        src: `<svg fill="black" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="68px" height="40px" viewBox="0 0 210 256" enable-background="new 0 0 210 256" xml:space="preserve">
     <path d="M174.55,144.5L174.55,144.5c-1.4-4-5-6.5-9.2-6.5H159V23c0-11.708-9.292-21-21-21H25C12.57,2,2,12.57,2,25v183
       c0,11.9,10.95,22,22.75,22l114.213,0c1.207,0,2.27,0.984,2.18,2.188c-0.095,1.266-1.153,1.812-2.393,1.812h-45.5L128,254h80
       L174.55,144.5z M82.05,220.2c-3.199,0-5.599-2.399-5.6-5.598c-0.001-3.045,2.557-5.602,5.602-5.602
       c3.199,0.001,5.598,2.401,5.598,5.6C87.55,217.8,85.25,220.2,82.05,220.2z M144,138h-19.65c-5.3,0-9.8,4.7-9.8,10l0,0
       c0,5.3,4.5,10,9.8,10h19.8v42H18V31h126V138z M80.636,134.85c23.663,0,43-19.21,43-43s-19.21-43-43-43S37.763,68.188,37.763,91.977
       C37.763,115.64,56.973,134.85,80.636,134.85z M78.261,95.497c-7.888-2.756-12.807-6.743-12.807-13.824
       c0-6.531,4.325-11.662,12.255-13.273v-7.082h5.937v6.743c4.919,0,8.481,1.018,11.11,2.417l-2.417,8.481
       c-1.951-0.806-5.131-2.163-9.499-2.163s-6.743,2.163-6.743,4.325c0,2.968,2.756,4.113,8.693,6.531
       c8.1,2.968,11.874,7.082,11.874,13.824c0,6.743-4.113,11.874-12.807,13.824v6.743h-5.937v-6.531c-5.131,0-10.305-1.357-12.807-2.756
       l2.163-8.693c2.756,1.357,7.082,2.968,11.662,2.968c4.919,0,7.336-2.163,7.336-5.131S83.944,97.702,78.261,95.497z"/>
     </svg>`,
        alt: 'Mobile Wallet',
        isSvg: true,
        title: "Mobile wallet"
      },
    ]
  },
    /** Linked Payment methods */
  LINK_PAYMENT_METHOD: {
    type: "null",
    rank: 1,
    desc: "Linked Payment Methods",
    logos: [
      {
        // src: "https://fms.tranzak.me/cdn/pf/89x7rgspk4w2keyu_undefined100323.45381095026logo.png",
        src: `<svg width="68" height="40" viewBox="0 0 234 225" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M103.5 7.09999C85.6 9.09999 65.8 17 51.1 28C32.2 42.3 18.8 62.2 12.2 86C9.8 94.7 9.5 97.1 9.5 113.5C9.5 129.9 9.8 132.3 12.2 141C20.8 171.7 40.9 196.2 68.3 209.5C118.3 233.6 177.7 216.8 206.7 170.3C228.9 134.6 228.2 89 204.7 53.8C182.6 20.6 143.6 2.59999 103.5 7.09999ZM125.8 66.3L126 89H138.1H150.2L146.6 82.6C144.6 79.1 143 76 143 75.7C143 75 152.1 69.7 152.5 70.2C153.1 70.9 168 97.9 168.6 99.4C169.2 100.8 167.1 101 147.6 101H126V106V111H147.5H169V116.5V122H147.5H126V141C126 161 126.5 163.9 130.6 166C133.7 167.7 141.7 166.7 147 164C149.2 162.9 151.4 162.1 151.8 162.4C152.3 162.7 153.7 165.1 155 167.9C157.7 173.8 157.4 174.4 150.2 178.3C139 184.4 125.7 185.7 117.3 181.5C106.6 176.2 104 168.1 104 140.8V122H92C85.4 122 80 122.2 80 122.4C80 122.7 81.6 125.6 83.5 128.9C85.4 132.1 87 135.2 87 135.7C87 137.1 78 141.4 77.1 140.4C76.1 139.2 61 112.2 61 111.5C61 111.2 70.7 111 82.5 111H104V106V101H82.5H61V95V89H82.5H104V68.1V47.1L109.8 46C112.9 45.3 117.1 44.4 119 44C125.8 42.4 125.5 41.2 125.8 66.3Z" fill="#990099"/>
        </svg>`,
        alt: 'Linked Payment Methods',
        isSvg: true,
        title: "Linked Payment Methods"
      },
    ]
  },
  /** PayPal payment. */
  PAY_PAL: {
    type: "7",
    rank: 5,
    desc: "",
    logos: [
      {
        src: "https://fms.tranzak.me/cdn/pf/z3f7rz5qth2ay9bb_undefined103750.62388862956pp.png",
        alt: "PayPal",
        styled: true
      }
    ]
  },
});

var CUR_PAYMENT_METHOD_TYPES = Object.values(PAYMENT_METHOD_TYPES)
export const PAYMENT_METHOD_TYPES_ARRAY = CUR_PAYMENT_METHOD_TYPES;


          
export const LANGUAGE = navigator.language || navigator.languages[0] || 'fr';

export const CMR_COUNTRY_CODE = "CM";
export const CMR_TEL_CODE = "+237";
export const CURRENCY_XAF = "XAF";
export const CURRENCY_USD = "USD";

/**
 * @param {number | Date | undefined} date
 * @param {"full" | "long" | "medium" | "short" | undefined} style 
 * @returns 
 */
export function formatDate (date, style) {
  //console.log("date=222======", date);
  try {
    return new Intl.DateTimeFormat(
      navigator.language || (navigator.languages || [])[0] || 'en',
      {
        dateStyle: style
      }).format(new Date(date));
  } catch (error) {
    //console.log("date=222======", error);
  }
}

export function toDateTime (date) {
  if(!date) return '';
  //console.log("date=3333======", date);
  if(typeof date == 'string' && !date.toLowerCase().includes('t')){
    date = date.replace(" ", "T")+'Z';
  }
  try {
  return new Intl.DateTimeFormat(
    'fr' || navigator.language || (navigator.languages || [])[0] || 'en',
    {
      month: '2-digit',
      day: "2-digit",
      year: "numeric",
      hour: '2-digit',
      minute: '2-digit',
      // second: '2-digit'
    }
  ).format(date ? (new Date(date)) : (new Date())).split(',').join(' ');
  } catch (error) {
    //console.log("date=3333======", error);
    return date;
  }
}

export function formatCurrency(value, currency) {
  if (Number.isNaN(value)) return null;

  const number = value || 0;
  const formattedNumber = new Intl.NumberFormat(LANGUAGE).format(value);

  const result = LANGUAGE.toLowerCase().includes('fr') ? `${formattedNumber} ${currency}` : `${currency} ${formattedNumber}`;

  return result;

  let formated = new Intl.NumberFormat(LANGUAGE, { style: 'currency', currency: currency || 'XOF' }).format(number)

  return formated
};

export function isMobile() {
  return window.innerWidth <= 568 || window.innerHeight <= 400;
}
